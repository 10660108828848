import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Card,
  CardContent,
  Container,
  Unstable_Grid2 as Grid,
} from "@mui/material";
import { OverviewBudget } from "./overview-budget";
import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  BarChart,
  Bar,
} from "recharts";
import axiosInstance from "../../utils/AxiosInstance";
import urlConfigs from "../../configs/urlCongfig";
import Example from "../pagination/Pagination";
import FullScreenLoader from "../../components/Loaders/FullScreenLoader";
import { FlashAutoTwoTone } from "@mui/icons-material";
import { Col, Row } from "reactstrap";
import PieChartOverview from "./pie-chart";
import Chart from "react-apexcharts";
import { ApexOptions } from "apexcharts";

type Props = {};

const OverviewPage = (props: Props) => {
  const [happinessQuotient, setHappinessQuotient] = useState<any>([]);
  const [bloodGroup, setBloodGroup] = useState<any>([]);
  const [envFactor, setEnvFactor] = useState<any>([]);
  const [jobList, setJobList] = useState([]);
  const isMounted = useRef(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!isMounted.current) {
      getJobDetails();
      bloodGroupDistributionChart();
      happinessQuotientChart();
      environmentFactorCharts();
    }
    isMounted.current = true;
  }, []);

  const bloodGroupsData = ["A+", "A-", "B+", "B-", "AB+", "AB-", "O+", "O-"];

  const envFactorData = [
    "Environment",
    "Stagnate",
    "Meat Shop",
    "Garbage disposal",
    "Sanitation",
    "Mobile Tower",
  ];

  const getJobDetails = () => {
    setIsLoading(true);
    axiosInstance
      .get(urlConfigs.PROGRESS_REPORT)
      .then((response) => {
        setJobList(response.data[0]);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const bloodGroupDistributionChart = () => {
    setIsLoading(true);
    axiosInstance
      .get(urlConfigs.bloodGroupDistributionChart)
      .then((response) => {
        let arraySet: any = [];
        for (let i = 0; i < bloodGroupsData.length; i++) {
          let value =
            response.data.find((e: any) => e.BloodGroup === bloodGroupsData[i])
              ?.Count ?? null;

          arraySet.push(value ?? 0);
        }
        setBloodGroup([{ data: arraySet }]);
        console.log([{ data: arraySet }]);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const environmentFactorCharts = () => {
    setIsLoading(true);
    axiosInstance
      .get(urlConfigs.environmentFactorCharts)
      .then((response) => {
        let arraySet: any = [];
        for (let i = 0; i < envFactorData.length; i++) {
          let value =
            response.data.find((e: any) => e.question === envFactorData[i])
              ?.Count ?? null;

          arraySet.push(value ?? 0);
        }

        setEnvFactor([{ data: arraySet }]);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const happinessQuotientChart = () => {
    setIsLoading(true);
    axiosInstance
      .get(urlConfigs.happinessQuotientChart)
      .then((response) => {
        const ps = [
          {
            data: [
              parseInt(response.data.find((e: any) => e.rating == 1).Count),
              parseInt(response.data.find((e: any) => e.rating == 2).Count),
              parseInt(response.data.find((e: any) => e.rating == 3).Count),
              parseInt(response.data.find((e: any) => e.rating == 4).Count),
              parseInt(response.data.find((e: any) => e.rating == 5).Count),
              parseInt(response.data.find((e: any) => e.rating == 6).Count),
              parseInt(response.data.find((e: any) => e.rating == 7).Count),
              parseInt(response.data.find((e: any) => e.rating == 8).Count),
              parseInt(response.data.find((e: any) => e.rating == 9).Count),
              parseInt(response.data.find((e: any) => e.rating == 10).Count),
            ],
          },
        ];

        console.log(ps);

        setHappinessQuotient(ps);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [screenHeight, setScreenHeight] = useState(window.innerHeight);

  const optionsHappy: ApexOptions = {
    chart: {
      type: "bar",
      height: 300,
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "55%",
        // endingShape: "rounded",
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },
    colors: ["#518673", "#545454"],
    xaxis: {
      categories: ["1", "2", "3", "4", "5", "7", "6", "8", "9", "10"],
      title: {
        text: "Rating",
      },
    },
    yaxis: {
      title: {
        text: "Customers",
      },
    },
    fill: {
      opacity: 1,
    },
    title: {
      text: "Happiness Quotient",
    },
    tooltip: {
      y: {
        formatter: (val) => {
          return val + " Customers";
        },
      },
    },
  };

  const optionsBlood: ApexOptions = {
    chart: {
      type: "bar",
      height: 300,
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "55%",
        // endingShape: "rounded",
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },
    colors: ["#518673", "#545454"],
    xaxis: {
      categories: bloodGroupsData,
      title: {
        text: "Blood Type",
      },
    },
    yaxis: {
      title: {
        text: "Customers",
      },
    },
    fill: {
      opacity: 1,
    },
    title: {
      text: "Blood Group Distribution Chart",
    },
    tooltip: {
      y: {
        formatter: (val) => {
          return val + " Customers";
        },
      },
    },
  };

  useEffect(() => {
    // Update screen dimensions on window resize
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
      setScreenHeight(window.innerHeight);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  var options3: ApexOptions = {
    chart: {
      type: "pie",
    },
    labels: ["0-20%", "20-40%", "40-60%", "60-80%", "80-100%"],
    colors: ["#58A399", "#A8CD9F", "#74E291", "#496989", "#A5DD9B"],
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  };

  return (
    <>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
        }}
      >
        <Container maxWidth="xl">
          <Grid container spacing={3} justifyContent={"center"}>
            <Grid xs={12} sm={6} lg={3}>
              <OverviewBudget
                difference={12}
                positive
                sx={{ height: "100%" }}
                value={291}
                text="Customers"
                subtext="Current customers count"
                color="rgb(8 55 108 / 87%)"
              />
            </Grid>
            <Grid xs={12} sm={6} lg={3}>
              <OverviewBudget
                difference={12}
                positive
                sx={{ height: "100%" }}
                text="Moringa Score"
                subtext="Low moringa score (below 60%)"
                color="rgb(37 109 133 / 89%)"
                value={jobList.filter((e) => e["Moringa Score"] < 70).length}
              />
            </Grid>
            <Grid xs={12} sm={6} lg={3}>
              <OverviewBudget
                difference={12}
                positive
                sx={{ height: "100%" }}
                text="Sickness Score"
                subtext="High sickness score (above 50%)"
                color="rgb(43 72 101 / 83%)"
                value={jobList.filter((e) => e["Sickness Score"] > 50).length}
              />
            </Grid>
            <Grid xs={12} sm={6} lg={3}>
              <OverviewBudget
                difference={12}
                positive
                sx={{ height: "100%" }}
                text="Behaviour Score"
                color="rgb(75 140 124 / 96%)"
                subtext="Low behaviour score (below 50)"
                value={jobList.filter((e) => e["Behaviour Score"] < 50).length}
              />
            </Grid>
          </Grid>
          <Row>
            <Col className="mt-3">
              <Card>
                <CardContent>
                  <h5 className="ml-4 mb-3">Moringa Score</h5>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Chart
                      options={options3}
                      series={[
                        jobList.filter(
                          (e: any) => parseInt(e["Moringa Score"]) <= 20
                        ).length,
                        jobList.filter(
                          (e) =>
                            parseInt(e["Moringa Score"]) > 20 &&
                            parseInt(e["Moringa Score"]) < 40
                        ).length,
                        jobList.filter(
                          (e) =>
                            parseInt(e["Moringa Score"]) > 40 &&
                            parseInt(e["Moringa Score"]) < 60
                        ).length,
                        jobList.filter(
                          (e) =>
                            parseInt(e["Moringa Score"]) > 60 &&
                            parseInt(e["Moringa Score"]) < 80
                        ).length,
                        jobList.filter(
                          (e) =>
                            parseInt(e["Moringa Score"]) > 80 &&
                            parseInt(e["Moringa Score"]) < 100
                        ).length,
                      ]}
                      type="pie"
                      width={screenWidth / 2.85}
                      height={300}
                    />
                  </div>
                </CardContent>
              </Card>
            </Col>
            <Col className="mt-3">
              {/* <Card>
                <CardContent>
                  <h5 className="ml-4 mb-3">Sickness Score</h5>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <PieChartOverview
                      color={"rgba(37, 109, 133, 0.89)"}
                    ></PieChartOverview>
                  </div>
                </CardContent>
              </Card> */}
              <Card className="">
                <CardContent>
                  {/* <BarChart
                    width={screenWidth / 3}
                    height={300}
                    data={happinessQuotient}
                    margin={{
                      top: 5,
                      right: 30,
                      left: 20,
                      bottom: 5,
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="rating" padding={{ left: 10, right: 10 }} />
                    <YAxis allowDecimals={false} />
                    <Tooltip />
                    <Legend />
                    <Bar dataKey="Count" fill="#518673" />
                  </BarChart> */}

                  <Chart
                    options={optionsHappy}
                    series={happinessQuotient}
                    type="bar"
                    width={screenWidth / 2.85}
                    height={325}
                  />
                </CardContent>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col>
              <Card className="mt-4">
                <CardContent>
                  {/* <h5 className="mb-3">Blood Group Distribution Chart</h5> */}
                  {/* <BarChart
                    width={screenWidth / 3}
                    height={300}
                    data={bloodGroup}
                    margin={{
                      top: 5,
                      right: 30,
                      left: 20,
                      bottom: 5,
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis
                      dataKey="BloodGroup"
                      padding={{ left: 10, right: 10 }}
                    />
                    <YAxis allowDecimals={false} />
                    <Tooltip />
                    <Legend />
                    <Bar dataKey="Count" fill="#518673" />
                  </BarChart> */}

                  <Chart
                    options={optionsBlood}
                    series={bloodGroup}
                    type="bar"
                    width={screenWidth / 2.85}
                    height={325}
                  />
                </CardContent>
              </Card>
            </Col>
            <Col className="mt-4">
              <Card>
                <CardContent>
                  <h5 className="ml-4 mb-3">Sickness Score</h5>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Chart
                      options={options3}
                      series={[
                        jobList.filter(
                          (e: any) => parseInt(e["Sickness Score"]) <= 20
                        ).length,
                        jobList.filter(
                          (e) =>
                            parseInt(e["Sickness Score"]) > 20 &&
                            parseInt(e["Sickness Score"]) < 40
                        ).length,
                        jobList.filter(
                          (e) =>
                            parseInt(e["Sickness Score"]) > 40 &&
                            parseInt(e["Sickness Score"]) < 60
                        ).length,
                        jobList.filter(
                          (e) =>
                            parseInt(e["Sickness Score"]) > 60 &&
                            parseInt(e["Sickness Score"]) < 80
                        ).length,
                        jobList.filter(
                          (e) =>
                            parseInt(e["Sickness Score"]) > 80 &&
                            parseInt(e["Sickness Score"]) < 100
                        ).length,
                      ]}
                      type="pie"
                      width={screenWidth / 2.85}
                      height={300}
                    />
                  </div>
                </CardContent>
              </Card>
            </Col>
            {/* <Col className="mt-3">
              <Card>
                <CardContent>
                  <h5 className="ml-4 mb-3">Wellness Score</h5>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <PieChartOverview
                      color={"rgba(75, 140, 124, 0.96)"}
                    ></PieChartOverview>
                  </div>
                </CardContent>
              </Card>
            </Col> */}
          </Row>
        </Container>
        <Example
          jobList={jobList}
          envFactor={envFactor}
          isInsurance={true}
        ></Example>
      </Box>
      <FullScreenLoader isLoading={isLoading} />
    </>
  );
};

{
  /* <Grid xs={12} sm={12} lg={4}>
<Card className="mt-4">
  <CardContent>
    <PieChart width={screenWidth / 4.5} height={300}>
      <Pie
        data={data2}
        cx="50%"
        cy="50%"
        labelLine={false}
        label={renderCustomizedLabel}
        outerRadius={80}
        fill="#518673"
        dataKey="value"
      >
        {data.map((entry, index) => (
          <Cell
            key={`cell-${index}`}
            fill={COLORS[index % COLORS.length]}
          />
        ))}
      </Pie>
    </PieChart>
  </CardContent>
</Card>
</Grid> */
}

export default OverviewPage;
