import React, { useEffect, useRef, useState } from "react";
import { Card, Container, Nav, Navbar } from "reactstrap";
import { MaterialReactTable } from "material-react-table";
import FullScreenLoader from "../../../components/Loaders/FullScreenLoader";

type Props = {};

const HManagementReportPage = (props: Props) => {
  const [isLoading, setIsLoading] = useState(false);

  const [openModal, setOpenModal] = useState(false);

  const fieldShow = [
    { label: "#", key: "index" },
    // { label: "Hospital Code", key: "HospitalCode" },
    // { label: "Hospital Name", key: "HospitalName" },
    { label: "Date", key: "Date" },
    { label: "Beds", key: "Beds" },
    { label: "Availability", key: "Availability_beds" },
    { label: "ICU Beds", key: "ICUBeds" },
    { label: "Availability", key: "Availability_ICU" },
    {
      label: "Chest Specialists",
      key: "Chest_Specialists",
    },
    { label: "Availability", key: "Availability_check" },
  ];

  const tableHeaderData: any = fieldShow.map((key) => {
    return {
      accessor: key.key,
      accessorKey: key.key,
      enableColumnFilterModes: false,
      filterFn: "equals",
      header: key.label,
    };
  });

  const [jobList, setJobList] = useState<any>([
    {
      index: 1,
      HospitalCode: "H123",
      HospitalName: "Jupiter Thane",
      Date: "2024-02-02",
      Beds: 100,
      Availability_beds: 75,
      ICUBeds: 20,
      Availability_ICU: 15,
      Chest_Specialists: 3,
      Availability_check: 1,
    },
    {
      index: 1,
      HospitalCode: "H123",
      HospitalName: "Jupiter Thane",
      Date: "2024-02-03",
      Beds: 100,
      Availability_beds: 55,
      ICUBeds: 20,
      Availability_ICU: 10,
      Chest_Specialists: 3,
      Availability_check: 2,
    },
    {
      index: 1,
      HospitalCode: "H123",
      HospitalName: "Jupiter Thane",
      Date: "2024-02-04",
      Beds: 100,
      Availability_beds: 80,
      ICUBeds: 20,
      Availability_ICU: 4,
      Chest_Specialists: 3,
      Availability_check: 3,
    },
  ]);

  return (
    <Container fluid className="vh-100">
      <Navbar className="mt-2 mb-2" color="light">
        <Nav className="me-auto" navbar>
          <h4>Hospital Management</h4>
        </Nav>
      </Navbar>
      <Card
        style={{ borderRadius: 10 }}
        className="shadow p-3 pt-4 mb-5 bg-white "
      >
        <MaterialReactTable
          muiTableHeadCellProps={{
            sx: (theme) => ({
              background: "#faf4d4",
              color: theme.palette.text.primary,
            }),
          }}
          muiTableBodyRowProps={{
            sx: {
              backgroundColor: "rgb(228, 241, 221)",
              borderRight: "1px solid rgba(224,224,224,1)",
            },
          }}
          muiTopToolbarProps={{
            sx: (theme) => ({
              background: "#faf4d4",
              color: theme.palette.text.primary,
            }),
          }}
          muiBottomToolbarProps={{
            sx: (theme) => ({
              background: "#faf4d4",
              color: theme.palette.text.primary,
            }),
          }}
          columns={tableHeaderData}
          data={jobList}
          columnResizeMode="onChange"
          initialState={{ showColumnFilters: false }}
          filterFns={{
            customFilterFn: (row, id, filterValue) => {
              return row.getValue(id) === filterValue;
            },
          }}
        />
      </Card>

      <FullScreenLoader isLoading={isLoading} />
    </Container>
  );
};

export default HManagementReportPage;
