import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

const ErrorAlertModal = ({ isOpen, onClose, title, body }: any) => {
  return (
    <Modal centered isOpen={isOpen} toggle={onClose}>
      <ModalHeader toggle={onClose}>{title}</ModalHeader>
      <ModalBody>{body}</ModalBody>
      <ModalFooter>
        <Button color="danger" onClick={onClose}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ErrorAlertModal;
