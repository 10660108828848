import { configureStore } from "@reduxjs/toolkit";
import appStateSlice from "./features/appStateSlice";
import OverviewReducer from "../pages/overview/OverviewReducer";

export const store = configureStore({
  reducer: {
    appState: appStateSlice,
    toggleApp: OverviewReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
