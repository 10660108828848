import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { routes } from "./routes";
import "./style/style.css";
import "bootstrap/dist/js/bootstrap.min.js";
import "bootstrap/dist/css/bootstrap.min.css";
import { useAuth } from "./utils/Authguard";
import { Suspense, useEffect, useState } from "react";
import React from "react";

import PageWrapper from "./components/layout/PageWrapper";
import appRoutes from "./routes/appRoutes";

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

function App() {
  const MainLayout = React.lazy(() => import("./components/layout/MainLayout"));
  // Pages
  const LoginPage = React.lazy(() => import("./pages/login/LoginPage"));

  const WeatherPage = React.lazy(
    () => import("./pages/weatherPage/WeatherPage")
  );

  const { isAuthenticated, user } = useAuth();

  if (isAuthenticated === null) {
    return null;
  }
  return (
    <BrowserRouter>
      <Suspense fallback={loading}>
        <Routes>
          <Route path="/login" element={<LoginPage />} />
          <Route path="/weather" element={<WeatherPage />} />
          <Route
            path="/"
            element={
              isAuthenticated ? (
                <MainLayout />
              ) : (
                <Navigate to="/login" replace />
              )
            }
          >
            {appRoutes.map((route: any, index: any) => (
              <Route
                path={route.path}
                element={
                  route.company_type.includes(user?.company_type) ? (
                    <PageWrapper state={route.child ? undefined : route.state}>
                      {route.element}
                    </PageWrapper>
                  ) : (
                    <Navigate to="/login" replace />
                  )
                }
                key={route.path}
              >
                {route.child &&
                  route.child.map((routeCH: any, idx: any) => (
                    <Route
                      path={routeCH.path}
                      element={
                        <PageWrapper
                          state={routeCH.child ? undefined : routeCH.state}
                        >
                          {routeCH.element}
                        </PageWrapper>
                      }
                      key={routeCH.path}
                    ></Route>
                  ))}
              </Route>
            ))}
          </Route>
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
}

export default App;
