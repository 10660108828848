import { useState } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
  Form,
} from "reactstrap";
import {
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
} from "@mui/material";
import axiosInstance from "../../utils/AxiosInstance";
import urlConfigs from "../../configs/urlCongfig";

const HospitalDetailsModal = ({
  isOpen,
  onClose,
  onConfirm,
  triggerLoader,
}: any) => {
  const [hospitalInfo, setHospitalInfo] = useState<any>({
    HospitalName: "",
    Address: "",
    PhoneNumber: "",
    PinCode: "",
    Speciality: "",
    SystemOfMedication: "",
    Capacity: null,
    Admissions: null,
    DoctorUtilization: null,
    HospitalizationBasedOnDisease: "",
    OPDToIPD: false,
    PHIConsent: false,
    DrugsPrescribed: "",
  });

  const fieldShow = [
    { label: "Hospital Code", key: "HospitalCode", type: "input" },
    { label: "Hospital Name", key: "HospitalName", type: "input" },
    { label: "Address", key: "Address", type: "input" },
    { label: "Phone Number", key: "PhoneNumber", type: "input" },
    { label: "Pin Code", key: "PinCode", type: "input" },
    { label: "Speciality", key: "Speciality", type: "input" },
    { label: "System Of Medication", key: "SystemOfMedication", type: "input" },
    { label: "Capacity", key: "Capacity", type: "input" },
    { label: "Admissions", key: "Admissions", type: "input" },
    { label: "Doctor Utilization", key: "DoctorUtilization", type: "input" },
    {
      label: "Hospitalization Based On Disease",
      key: "HospitalizationBasedOnDisease",
      type: "input",
    },
    { label: "OPD To IPD", key: "OPDToIPD", type: "select" },
    { label: "PHI Consent", key: "PHIConsent", type: "select" },
    { label: "Drugs Prescribed", key: "DrugsPrescribed", type: "input" },
  ];

  const handleChange = (event: any) => {
    const { name, value, type, checked } = event.target;
    setHospitalInfo((prevInfo: any) => ({
      ...prevInfo,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleUpload = () => {
    console.log(hospitalInfo);
    triggerLoader(true);

    hospitalInfo.OPDToIPD = hospitalInfo.OPDToIPD === "Yes" ? true : false;

    hospitalInfo.PHIConsent = hospitalInfo.PHIConsent === "Yes" ? true : false;

    axiosInstance
      .post(urlConfigs.hospital, hospitalInfo)
      .then((response) => {
        onConfirm();
        triggerLoader(false);
      })
      .catch((error) => {
        triggerLoader(false);
      });
  };

  return (
    <Modal size="lg" centered isOpen={isOpen} toggle={onClose}>
      <Form onSubmit={handleUpload}>
        <ModalHeader toggle={onClose}>Create Hospital</ModalHeader>
        <ModalBody>
          <Row>
            {fieldShow.map((e, i) => (
              <Col lg={6} key={i}>
                {e.type === "select" ? (
                  <FormControl sx={{ minWidth: "100%" }}>
                    <InputLabel id="demo-simple-select-helper-label">
                      {e.label}
                    </InputLabel>
                    <Select
                      name={e.key}
                      id="outlined-adornment-email"
                      label={e.key}
                      onChange={handleChange}
                      onClose={(e) => {
                        document.body.style.overflow = "visible";
                      }}
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      <MenuItem value={"Yes"}>Yes</MenuItem>
                      <MenuItem value={"No"}>No</MenuItem>
                    </Select>
                  </FormControl>
                ) : (
                  <FormControl fullWidth className="mb-3">
                    <InputLabel htmlFor="outlined-adornment-email">
                      {e.label}
                    </InputLabel>
                    <OutlinedInput
                      name={e.key}
                      id="outlined-adornment-email"
                      label={e.key}
                      onChange={handleChange}
                      required
                    />
                  </FormControl>
                )}
              </Col>
            ))}
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={handleUpload}>
            Save
          </Button>
          <Button color="secondary" onClick={onClose}>
            Cancel
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default HospitalDetailsModal;
