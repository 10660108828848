const HReportsHeaderKeys = {
  moringaCsvHeader: [
    { key: "org_id", label: "Customer Id" },
    { key: "customer_name", label: "Customer Name" },
    { key: "Age", label: "Age" },
    { key: "Gender", label: "Gender" },
    { key: "BMI", label: "BMI Range" },
    { key: "Nature of Job", label: "Nature of Job" },
    { key: "Activity Level", label: "Activity Level" },
    { key: "Exercise Level", label: "Exercise Level" },
    { key: "Eating Habits", label: "Eating Habits" },
    { key: "Sleep Duration", label: "Sleep Duration" },
    { key: "Any Surgical History?", label: "Any Surgical History?" },
    { key: "Any Medical History?", label: "Any Medical History" },
    { key: "Smoking", label: "Tobacco" },
    { key: "Drinking", label: "Drinking" },
    { key: "Family Medical History?", label: "Family Medical History?" },
    { key: "Any stress?", label: "Mental Health Issues?" },
    { key: "Environment", label: "Environment" },
    { key: "Vaccination", label: "Vaccination" },
    { key: "moringaScore", label: "Moringa Score" },
  ],
  sicknessCsvHeader: [
    { key: "org_id", label: "Customer Id" },
    { key: "customer_name", label: "Customer name" },
    { key: "Nature of Job", label: "Nature of Job" },
    { key: "Activity Level", label: "Activity Level" },
    { key: "Exercise Level", label: "Exercise Level" },
    { key: "Eating Habits", label: "Eating Habits" },
    { key: "Sleep Duration", label: "Sleep Duration" },
    { key: "Any Medical History?", label: "Any Medical History" },
    { key: "Smoking", label: "Tobacco" },
    { key: "Drinking", label: "Drinking" },
    { key: "Any stress?", label: "Mental Health Issues?" },
    { key: "Environment", label: "Environment" },
    { key: "Vaccination", label: "Vaccination" },
    { key: "Upload Reports-OCR", label: "Upload Reports-OCR" },
    // { key: "GOALS", label: "Goal setting" },
    // { key: "Monitoring goals", label: "Monitoring goals" },
    { key: "sickness_likelihood_score", label: "Sickness Likelihood Score" },
  ],

  behvaviourCsvHeader: [
    { key: "org_id", label: "Customer Id" },
    { key: "ModelParameterKey", label: "Factor Name" },
    { key: "ModelParameterDesc", label: "Factor Description" },
    { key: "ModelParameterHighDesc", label: "High Factor" },
    { key: "ModelParameterLowDesc", label: "Low Factor" },
    { key: "ModelWeightage", label: "Max" },
    { key: "ParameterScore", label: "Score" },
    { key: "OverallScore", label: "OverallScore" },
  ],
  ProgressReportHeader: [
    { key: "id", label: "Customer Id" },
    { key: "name", label: "Customer Name" },
    { key: "SCOREDATE", label: "Date" },
    { key: "Moringa Score", label: "Moringa Score" },
    { key: "Behaviour Score", label: "Behaviour Score" },
    { key: "Wellness Score", label: "Wellness Score" },
    { key: "Sickness Score", label: "Sickness Score" },
    { key: "Sleep Score", label: "Sleep Score" },
    { key: "Carbon Score", label: "Carbon Score" },
    { key: "Sustainability Score", label: "Sustainability Score" },
    { key: "Progress", label: "Progress" },
  ],

  goalComplianceHeader: [
    { key: "Customer_id", label: "Customer Id" },
    { key: "customer_name", label: "Customer name" },
    { key: "age", label: "Age" },
    { key: "GoalSetting", label: "Goal setting" },
    { key: "GoalMonitoringFreq", label: "Goal Monitoring" },
    { key: "GoalAchievementScore", label: "Goal Achievement Score" },
    { key: "GoalStartDate", label: "Goal Start Date" },
    { key: "GoalEndDate", label: "Goal Start Date" },
    { key: "baseLocation", label: "City" },
  ],
  campaignHeader: [
    { key: "CampaignDesc", label: "Campaigns" },
    { key: "customer_count", label: "Customer Count" },
    { key: "avg score", label: "Campaign Achievement Score" },
    { key: "CampaignStartDate", label: "Start Date" },
    { key: "CampaingEndDate", label: "End Date" },
    { key: "feedback", label: "Campaign Feedback" },
    { key: "Outreach Program", label: "Outreach Program" },
    { key: "Care Co-ordinator", label: "Care Co-ordinator" },
    { key: "name", label: "Country" },
  ],
  goodHeathReport: [
    { key: "org_id", label: "Customer Id" },
    { key: "customer_name", label: "Customer name" },
    { key: "age", label: "Age" },
    { key: "Gender", label: "Gender" },
    { key: "Any Medical History?", label: "Any Medical History" },
    { key: "Any stress?", label: "Mental Health Issues?" },
    { key: "Upload Reports-OCR", label: "Upload Reports-OCR" },
    { key: "moringaScore", label: "Moringa Scores" },
    { key: "sickness_likelihood_score", label: "Sickness likely Score" },
    { key: "behaviourScore", label: "Behaviour Scores" },
    { key: "baseLocation", label: "City" },
  ],

  behvaviourCustomerWise: [
    { key: "org_id", label: "Customer Id" },
    { key: "ModelParameterKey", label: "Factor Name" },
    { key: "ModelParameterDesc", label: "Factor Description" },
    { key: "OverallScore", label: "OverallScore" },
  ],
  riskAssessment: [
    { key: "id", label: "Id" },
    { key: "name", label: "Name" },
    { key: "SCOREDATE", label: "Date" },
    { key: "Sickness Score", label: "Sickness Score" },
    { key: "Wellness Score", label: "Wellness Score" },
    { key: "Moringa Score", label: "Moringa Score" },
    { key: "Sleep Score", label: "Sleep Score" },
    { key: "Behaviour Score", label: "Behaviour Score" },
    { key: "temp", label: "Temperature (°C)" },
    { key: "aqi", label: "Air Quality" },
    { key: "uvi", label: "UV Index" },
  ],

  healthcareResource: [
    { key: "name", label: "Name" },
    { key: "COUNT", label: "Customer Count" },
    { key: "percentage", label: "% Customers" },
    { key: "Advanced", label: "Advanced" },
    { key: "Moderate", label: "Moderate" },
    { key: "Threshold", label: "Threshold" },
    { key: "guidance", label: "Guidance" },
  ],
  regulatoryCompliance: [
    { key: "name", label: "Name" },
    { key: "COUNT", label: "Customer Count" },
    { key: "percentage", label: "% Customers" },
    { key: "guidance", label: "Guidance" },
  ],

  customizedPremiums: [
    { key: "name", label: "Name" },
    { key: "COUNT", label: "Customers Count" },
    { key: "percentage", label: "% Customers" },
    { key: "Advanced", label: "Advanced" },
    { key: "Moderate", label: "Moderate" },
    { key: "Threshold", label: "Threshold" },
    { key: "guidance", label: "Guidance" },
  ],
  predictiveAnalytics: [
    { key: "name", label: "Name" },
    { key: "COUNT", label: "Customers Count" },
    { key: "percentage", label: "% Customers" },
    { key: "Advanced", label: "Advanced" },
    { key: "Moderate", label: "Moderate" },
    { key: "Threshold", label: "Threshold" },
    { key: "guidance", label: "Guidance" },
  ],
  dataDrivenUnderwriting: [
    { key: "name", label: "Name" },
    { key: "COUNT", label: "Customers Count" },
    { key: "percentage", label: "% Customers" },
    { key: "Advanced", label: "Advanced" },
    { key: "Moderate", label: "Moderate" },
    { key: "Threshold", label: "Threshold" },
    { key: "guidance", label: "Guidance" },
  ],
  insuranceTailoringReport: [
    { key: "customerId", label: "Customer Id" },
    { key: "customerName", label: "Customer Name" },
    { key: "createdDate", label: "Date" },
    { key: "symptomsNames", label: "Symptoms Names" },
    { key: "diseaseNames", label: "Disease Names" },
    { key: "city", label: "City" },
  ],

  medicationData: [
    { key: "id", label: "Id" },
    { key: "MedicationName", label: "MedicationName" },
    { key: "Dosage", label: "Dosage" },
    { key: "PharmaCompany", label: "PharmaCompany" },
    { key: "Uses", label: "Uses" },
    { key: "Duration", label: "Duration" },
  ],

  medicationDataInfo: [
    { key: "country_id", label: "Country id" },
    { key: "state_id", label: "State id" },
    { key: "city_id", label: "City id" },
    { key: "location_id", label: "Location id" },
    { key: "gender", label: "Gender" },
    { key: "pre_existing_conditions", label: "Pre existing conditions" },
    { key: "age_band", label: "Age band" },
    { key: "date_month", label: "Date month" },
    { key: "symptoms", label: "Symptoms" },
    { key: "disease_id", label: "Disease id" },
    { key: "drugs_prescribed", label: "Drugs prescribed" },
    { key: "drugs_used", label: "Drugs used" },
  ],
};

export default HReportsHeaderKeys;
