import { ChangeEvent, useState } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Input,
} from "reactstrap";
import axiosInstance from "../../utils/AxiosInstance";
import urlConfigs from "../../configs/urlCongfig";

const FileUploadModal = ({
  isOpen,
  onClose,
  onConfirm,
  triggerLoader,
  isReport,
  customerId,
}: any) => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      setSelectedFile(event.target.files[0]);
    }
  };

  const handleUpload = () => {
    if (selectedFile) {
      if (isReport) {
        triggerLoader(true);
        const formData = new FormData();
        formData.append("report", selectedFile);
        formData.append("customerId", customerId);

        axiosInstance
          .post(urlConfigs.REPORT_UPLOAD, formData)
          .then((response) => {
            console.log("File uploaded successfully");
            onConfirm();
            triggerLoader(false);
            // Handle success response
          })
          .catch((error) => {
            console.error("Error uploading file:", error);
            triggerLoader(false);
            // Handle error response
          });
      } else {
        triggerLoader(true);
        const formData = new FormData();
        formData.append("file", selectedFile);

        axiosInstance
          .post(urlConfigs.FILE_LIST, formData)
          .then((response) => {
            console.log("File uploaded successfully");
            onConfirm();
            triggerLoader(false);
            // Handle success response
          })
          .catch((error) => {
            console.error("Error uploading file:", error);
            triggerLoader(false);
            // Handle error response
          });
      }
    }
  };

  return (
    <Modal centered isOpen={isOpen} toggle={onClose}>
      <ModalHeader toggle={onClose}>File Upload</ModalHeader>
      <ModalBody>
        <FormGroup>
          <Input
            type="file"
            id="exampleCustomFileBrowser"
            name="customFile"
            label={"choose an image file"}
            onChange={handleFileChange}
            // invalid={invalidFile}
          />
        </FormGroup>
      </ModalBody>
      <ModalFooter>
        <Button color="danger" onClick={handleUpload}>
          Upload
        </Button>
        <Button color="secondary" onClick={onClose}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default FileUploadModal;
