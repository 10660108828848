import { ChangeEvent, useState } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Input,
} from "reactstrap";
import axiosInstance from "../../utils/AxiosInstance";
import urlConfigs from "../../configs/urlCongfig";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";

const HospitalFileUploadModal = ({
  isOpen,
  onClose,
  onConfirm,
  triggerLoader,
  hospitalList,
}: any) => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      setSelectedFile(event.target.files[0]);
    }
  };

  const handleUpload = () => {
    if (selectedFile) {
      triggerLoader(true);
      const formData = new FormData();
      formData.append("file", selectedFile);

      axiosInstance
        .post(urlConfigs.FILE_LIST, formData)
        .then((response) => {
          console.log("File uploaded successfully");
          onConfirm();
          triggerLoader(false);
          // Handle success response
        })
        .catch((error) => {
          console.error("Error uploading file:", error);
          triggerLoader(false);
          // Handle error response
        });
    }
  };

  return (
    <Modal centered isOpen={isOpen} toggle={onClose}>
      <ModalHeader toggle={onClose}>Customer Upload</ModalHeader>
      <ModalBody>
        {/* <FormControl sx={{ minWidth: "100%", marginBottom: "1rem" }}>
          <InputLabel id="demo-simple-select-helper-label">
            Select Hospital
          </InputLabel>
          <Select
            name={"select"}
            id="outlined-adornment-email"
            label="demo-simple-select-helper-label"
            // onChange={handleChange}
            onClose={(e) => {
              document.body.style.overflow = "visible";
            }}
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            {hospitalList.map((e: any) => (
              <MenuItem value={e.id}>{e.HospitalCode}</MenuItem>
            ))}
          </Select>
        </FormControl> */}
        <FormGroup>
          <Input
            type="file"
            id="exampleCustomFileBrowser"
            name="customFile"
            label={"choose an image file"}
            onChange={handleFileChange}
          />
        </FormGroup>
      </ModalBody>
      <ModalFooter>
        <Button color="danger" onClick={handleUpload}>
          Upload
        </Button>
        <Button color="secondary" onClick={onClose}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default HospitalFileUploadModal;
