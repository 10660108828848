import { Box, CardContent, Container } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Card, Col, Row } from "reactstrap";
import Chart from "react-apexcharts";
import { ApexOptions } from "apexcharts";

export default function EsgDashboard() {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [screenHeight, setScreenHeight] = useState(window.innerHeight);
  useEffect(() => {
    // Update screen dimensions on window resize
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
      setScreenHeight(window.innerHeight);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const option1: ApexOptions = {
    chart: {
      type: "bar",
      height: 350,
    },
    plotOptions: {
      bar: {
        colors: {
          ranges: [
            {
              from: -100,
              to: -46,
              color: "#F15B46",
            },
            {
              from: -45,
              to: 0,
              color: "#FEB019",
            },
          ],
        },
        columnWidth: "80%",
      },
    },
    dataLabels: {
      enabled: false,
    },
    yaxis: {
      // title: {
      //   text: "",
      // },
      labels: {
        formatter: function (y: any) {
          return y.toFixed(0) + "%";
        },
      },
    },
    title: {
      text: "ESG Comparison",
    },
    xaxis: {
      categories: [
        "Environment Factors",
        "Health",
        "Employee Safety",
        "Reporting",
      ],
      labels: {
        rotate: -90,
      },
    },
  };

  const options2: ApexOptions = {
    chart: {
      type: "bar",
      height: 300,
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "55%",
        // endingShape: "rounded",
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },
    colors: ["#518673", "#545454"],
    xaxis: {
      categories: ["C-1", "C-2", "C-3", "C-4", "C-5", "C-6", "C-7"],
      title: {
        text: "Company",
      },
    },
    yaxis: {
      title: {
        text: "Rating",
      },
    },
    fill: {
      opacity: 1,
    },
    title: {
      text: "ESG Rating",
    },
    tooltip: {
      y: {
        formatter: (val) => {
          return val + " %";
        },
      },
    },
  };

  var options3: ApexOptions = {
    chart: {
      type: "pie",
    },
    labels: [
      "Mental Health",
      "Assessments",
      "Improvements",
      "Sustainable Practices",
      "Water Utilization",
      "Waste Management",
      "Energy Consumption",
      "Wellness Programs",
      "Risk Assessments",
    ],
    colors: [
      "#cae4bc",
      "#CAE4BC",
      "#E8F3E2",
      "#F2E180", //
      "#FAF4D4", //
      "#F2E180", //
      "#7DBC5D", //
      "#B0D69D",
      "#73bc4f",
    ],
    title: {
      text: "ESG Report",
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  };

  var option4: ApexOptions = {
    chart: {
      height: 350,
      type: "line",
      dropShadow: {
        enabled: true,
        color: "#000",
        top: 18,
        left: 7,
        blur: 10,
        opacity: 0.2,
      },
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    colors: ["#77B6EA", "#545454"],
    dataLabels: {
      enabled: true,
    },
    stroke: {
      curve: "smooth",
    },
    title: {
      text: "ESG History For C-1",
      align: "left",
    },
    grid: {
      borderColor: "#e7e7e7",
      row: {
        colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
        opacity: 0.5,
      },
    },
    markers: {
      size: 1,
    },
    xaxis: {
      categories: ["22-Dec", "23-Apr", "23-Jun", "23-Sep", "23-Oct", "24-Jan"],
      title: {
        text: "Month",
      },
    },
    yaxis: {
      title: {
        text: "Rating",
      },
      min: 5,
      max: 40,
    },
    legend: {
      position: "top",
      horizontalAlign: "right",
      floating: true,
      offsetY: -25,
      offsetX: -5,
    },
  };

  const options5: ApexOptions = {
    chart: {
      type: "bar",
      height: 300,
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "55%",
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },
    colors: ["#518673", "#545454"],
    xaxis: {
      categories: [
        "2021",
        "2022",
        "2023",
        "2024",
        "2025",
        "2026",
        "2027",
        "2028",
      ],
      title: {
        text: "Year",
      },
    },
    yaxis: {
      title: {
        text: "Rating",
      },
    },
    fill: {
      opacity: 1,
    },
    title: {
      text: "ESG Rating",
    },
    tooltip: {
      y: {
        formatter: (val) => {
          return val + " %";
        },
      },
    },
  };
  // const data = [
  //   ["Category", "Environmental & sustainability", "Social", "Governance"],
  //   [
  //     "Measures",
  //     "Environment management",
  //     "Strong retention and recruitment plarining",
  //     "Transparent accounting",
  //   ],
  //   [
  //     "",
  //     "Reduction in water resources",
  //     "Developing diverse work culture",
  //     "Stakeholder relationship management",
  //   ],
  //   [
  //     "",
  //     "E-waste reduction",
  //     "Employee training & engagement",
  //     "Whistleblower policies",
  //   ],
  //   ["", "", "Gender equality", "Cybersecurity governance"],
  //   ["", "", "Employee race equality", "Fraud prevention"],
  //   ["", "", "Religion equality", "Employee data and information security"],
  //   ["", "", "", "Food and product safety"],
  //   ["", "", "", "Employee accident prevention"],
  // ];
  const data = [
    [
      "E- waste reduction",
      "Employee training & engagement",
      "Gender equality",
      "Food and product safety",
      "Transparent accounting method",
      "Employee data and information security",
    ],
    [
      "Reduction in water resources",
      "Developing diverse work culture",
      "Employee race equality",
      "Employee accident prevention",
      "Whistleblower policies",
      "Cybersecurity governance",
    ],
    [
      "Environment management",
      "Strong retention and recruitment planning",
      "Religion equality",
      "Clean environment",
      "Stakeholder relationship",
      "Fraud prevention",
    ],
  ];

  const data2 = [
    [
      "Governance",
      "Hiring of women employee. \n percentage Percentage of employee that belongs to minority,person with disability",
      "Hire 35% of women at management level",
      "45% 29%",
      "32% 16%",
    ],
    [
      "Corporate ethics",
      "Employee percentage who completed corporate ethics and corporate behaviour training",
      "Maintain 100% percentage",
      "100%",
      "90%",
    ],
    [
      "Environment",
      "Implement zero carbon emission assets Percent of budget for environment safety",
      "Establish 10% environment safety budget from overall budget",
      "6%",
      "3%",
    ],
    [
      "Social",
      "Execute ten health check up program for employees and general public a year",
      "Complete 2 health checkup program in 2 months",
      "5 health check up program",
      "3 health check up program",
    ],
  ];

  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
      }}
    >
      <Container maxWidth="xl">
        <Row>
          <Col className="mt-3">
            <Card>
              <CardContent>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Chart
                    options={option1}
                    series={[{ name: "ESG", data: [-32, -45, 59, 66] }]}
                    type="bar"
                    width={screenWidth / 2.85}
                    height={300}
                  />
                </div>
              </CardContent>
            </Card>
          </Col>
          <Col className="mt-3">
            <Card>
              <CardContent>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Chart
                    options={options2}
                    series={[
                      { name: "ESG", data: [20, 15, 20, 18, 25, 10, 10] },
                    ]}
                    type="bar"
                    width={screenWidth / 2.85}
                    height={300}
                  />
                </div>
              </CardContent>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col className="mt-3">
            <Card>
              <CardContent>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Chart
                    options={options3}
                    series={[65, 89, 60, 66, 85, 79, 82, 86, 81]}
                    type="pie"
                    width={screenWidth / 2.85}
                    height={300}
                  />
                </div>
              </CardContent>
            </Card>
          </Col>
          <Col className="mt-3">
            <Card>
              <CardContent>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: 300,
                  }}
                >
                  <Chart
                    options={option4}
                    series={[{ name: "ESG", data: [20, 15, 20, 18, 25, 10] }]}
                    type="line"
                    width={screenWidth / 2.85}
                    height={300}
                  />
                </div>
              </CardContent>
            </Card>
          </Col>
        </Row>
        <Col className="mt-3">
          <Card>
            <CardContent>
              <h5>Environmental and governance framework model</h5>
              <table className="colored-table mt-3">
                <thead>
                  <tr>
                    <th
                      style={{
                        backgroundColor: "#E4F1DD",
                        color: "#000",
                        textAlign: "center",
                      }}
                    >
                      Environmental & sustainability
                    </th>
                    <th
                      style={{
                        backgroundColor: "#FAF4D4",
                        color: "#000",
                        textAlign: "center",
                      }}
                    >
                      Human capital & culture
                    </th>
                    <th
                      style={{
                        backgroundColor: "#FAF4D4",
                        color: "#000",
                        textAlign: "center",
                      }}
                    >
                      Diversity & inclusion
                    </th>
                    <th
                      style={{
                        backgroundColor: "#FAF4D4",
                        color: "#000",
                        textAlign: "center",
                      }}
                    >
                      Health & safety
                    </th>
                    <th
                      style={{
                        backgroundColor: "#E4F1DD",
                        color: "#000",
                        textAlign: "center",
                      }}
                    >
                      Governance
                    </th>
                    <th
                      style={{
                        backgroundColor: "#E4F1DD",
                        color: "#000",
                        textAlign: "center",
                      }}
                    >
                      Cyber security & data protection
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {data.map((e, i) => (
                    <tr key={i}>
                      <td style={{ textAlign: "center" }}>{e[0]}</td>
                      <td style={{ textAlign: "center" }}>{e[1]}</td>
                      <td style={{ textAlign: "center" }}>{e[2]}</td>
                      <td style={{ textAlign: "center" }}>{e[3]}</td>
                      <td style={{ textAlign: "center" }}>{e[4]}</td>
                      <td style={{ textAlign: "center" }}>{e[5]}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </CardContent>
          </Card>
        </Col>
        <Col className="mt-3">
          <Card>
            <CardContent>
              <h5>ESG report with target and approach</h5>
              <table className="colored-table mt-3">
                <thead>
                  <tr>
                    <th
                      style={{
                        backgroundColor: "#FAF4D4",
                        color: "#000",
                        textAlign: "center",
                      }}
                    >
                      Objectives
                    </th>
                    <th
                      style={{
                        backgroundColor: "#E4F1DD",
                        color: "#000",
                        textAlign: "center",
                      }}
                    >
                      Approach
                    </th>
                    <th
                      style={{
                        backgroundColor: "#E4F1DD",
                        color: "#000",
                        textAlign: "center",
                      }}
                    >
                      Target in 2023
                    </th>
                    <th
                      style={{
                        // backgroundColor: "#FAF4D4",
                        backgroundColor: "#E4F1DD",
                        color: "#000",
                        textAlign: "center",
                      }}
                    >
                      2022 result
                    </th>
                    <th
                      style={{
                        backgroundColor: "#E4F1DD",
                        color: "#000",
                        textAlign: "center",
                      }}
                    >
                      2021 result
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {data2.map((e, i) => (
                    <tr key={i}>
                      <td style={{ textAlign: "center" }}>{e[0]}</td>
                      <td style={{ textAlign: "center" }}>{e[1]}</td>
                      <td style={{ textAlign: "center" }}>{e[2]}</td>
                      <td style={{ textAlign: "center" }}>{e[3]}</td>
                      <td style={{ textAlign: "center" }}>{e[4]}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </CardContent>
          </Card>
        </Col>
      </Container>
    </Box>
  );
}
