import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Input,
  Nav,
  Navbar,
  Row,
} from "reactstrap";
import FullScreenLoader from "../../../components/Loaders/FullScreenLoader";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
  OutlinedInput,
  Unstable_Grid2 as Grid,
  ListItemIcon,
} from "@mui/material";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import axiosInstance from "../../../utils/AxiosInstance";
import { Circle } from "@mui/icons-material";
import urlConfigs from "../../../configs/urlCongfig";
import { useNavigate } from "react-router-dom";

type Props = {};

const CCreateTicket = (props: Props) => {
  const [countries, setCountries] = React.useState([]);
  const [states, setStates] = React.useState([]);
  const [cities, setCities] = React.useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showUser, setShowUser] = useState(false);
  const [value, setValue] = useState("");

  const [inpuValue, setInpuValue] = useState<any>({});

  const isMounted = useRef(false);

  const [phoneNumber, setPhoneNumber] = useState("");

  const [customer, setCustomer] = useState<any>({});

  React.useEffect(() => {
    if (!isMounted.current) {
      getCountriesApi();
    }

    isMounted.current = true;
  }, []);

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;

    setInpuValue({
      ...inpuValue,
      [name]: value,
    });
  };

  const getCitiesApi = (state_id: any) => {
    axiosInstance
      .post("https://staging-api.moringa-ai.com/Admin/create/cities/search", {
        state_id: state_id,
      })
      .then((response: any) => {
        setCities(response.data);
      })
      .catch((error: any) => {
        console.error(error);
      });
  };

  const getStatesApi = (countryId: any) => {
    axiosInstance
      .post("https://staging-api.moringa-ai.com/Admin/create/states/search", {
        country_id: countryId,
      })
      .then((response: any) => {
        setStates(response.data);
      })
      .catch((error: any) => {
        console.error(error);
      });
  };

  const getCountriesApi = () => {
    axiosInstance
      .get("https://staging-api.moringa-ai.com/Admin/create/countries")
      .then((response: any) => {
        setCountries(response.data);
      })
      .catch((error: any) => {
        console.error(error);
      });
  };

  const getCustomerDetails = () => {
    axiosInstance
      .get(urlConfigs.CALLCENTER + "/customer/" + phoneNumber)
      .then((response: any) => {
        setCustomer(response.data.customer);
        setShowUser(true);
      })
      .catch((error: any) => {
        setIsLoading(false);
        console.error(error);
      });
  };

  const createTicket = (data: any) => {
    axiosInstance
      .post(urlConfigs.CALLCENTER, data)
      .then((response: any) => {
        setShowUser(false);
        setCustomer({});
        setInpuValue({});
        setPhoneNumber("");
        setStates([]);
        setCities([]);
        console.log(response);
      })
      .catch((error: any) => {
        setIsLoading(false);
        console.error(error);
      });
  };

  const handlePhoneNumberChange = (e: any) => {
    const inputPhoneNumber = e.target.value.replace(/\D/g, "");
    if (inputPhoneNumber.length <= 10) {
      setPhoneNumber(inputPhoneNumber);
    }
  };

  const callCenterData = [
    { label: "Moringa Hangs" },
    { label: "Moringa down" },
    { label: "Errors Reported" },
    { label: "How do I enter data" },
    { label: "Reminders/Notifications" },
    { label: "Repeat tickets" },
    { label: "Reviews and Feedback" },
    { label: "Training coverage" },
    { label: "Unable to download" },
    { label: "Use moringa tables" },
    { label: "Where do I get password/otp" },
    { label: "FAQs" },
  ];

  return (
    <Container fluid className="vh-100" style={{ minWidth: "100%" }}>
      <Navbar className="mt-2 mb-2" color="light">
        <FormControl sx={{ minWidth: "40%" }}>
          <InputLabel id="demo-simple-select-helper-label">
            Select Type
          </InputLabel>
          <Select
            name="TicketType"
            id="outlined-adornment-email"
            label="Select Type"
            onChange={(e, val: any) => {
              if (val.props.value == "") {
                return;
              }
              handleInputChange(e);
            }}
            onClose={(e) => {
              document.body.style.overflow = "visible";
            }}
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>

            {callCenterData.map((e: any, i) => (
              <MenuItem value={i + 1}>{e.label}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </Navbar>
      <Card
        style={{ borderRadius: 10 }}
        className="shadow p-3 pt-4 mb-5 bg-white"
      >
        <Row>
          <Col md="4" className="mb-3">
            <Box sx={{ display: "flex", flexWrap: "wrap" }}>
              <FormControl fullWidth variant="outlined">
                <InputLabel htmlFor="mobile">Mobile No.</InputLabel>
                <OutlinedInput
                  type="tel"
                  key="mobile"
                  value={phoneNumber}
                  onChange={handlePhoneNumberChange}
                  name="phoneNumber"
                  id="mobile"
                  label="Mobile No."
                />
              </FormControl>
            </Box>
          </Col>

          {phoneNumber.length == 10 && (
            <Col md="2" style={{ alignSelf: "center", paddingBottom: "1rem" }}>
              <Button
                variant="outlined"
                className=""
                color="success"
                type="button"
                onClick={() => {
                  getCustomerDetails();
                }}
              >
                Submit
              </Button>
            </Col>
          )}
        </Row>

        {showUser && (
          <>
            <h5 className="mb-3">Customer Info</h5>
            <Grid>
              <FormControl
                style={{
                  minWidth: "40%",
                  marginRight: "10px",
                  marginBottom: "10px",
                }}
              >
                <InputLabel htmlFor="outlined-adornment-name">Name</InputLabel>
                <OutlinedInput
                  id="outlined-adornment-name"
                  label="Name"
                  disabled
                  value={customer?.name}
                />
              </FormControl>
              <FormControl
                style={{
                  minWidth: "40%",
                  marginRight: "10px",
                  marginBottom: "10px",
                }}
              >
                <InputLabel htmlFor="outlined-adornment-customerId">
                  Customer Id
                </InputLabel>
                <OutlinedInput
                  id="outlined-adornment-customerId"
                  label="customerId"
                  value={customer?.id}
                  disabled
                />
              </FormControl>
              <FormControl
                style={{
                  minWidth: "40%",
                  marginRight: "10px",
                  marginBottom: "10px",
                }}
              >
                <InputLabel htmlFor="outlined-adornment-email">
                  Email
                </InputLabel>
                <OutlinedInput
                  id="outlined-adornment-email"
                  label="Email"
                  value={customer?.email}
                  disabled
                />
              </FormControl>
              <FormControl
                style={{
                  minWidth: "40%",
                  marginRight: "10px",
                  marginBottom: "10px",
                }}
              >
                <InputLabel htmlFor="outlined-adornment-phone">
                  Phone Number
                </InputLabel>
                <OutlinedInput
                  id="outlined-adornment-phone"
                  label="Phone Number"
                  disabled
                  value={customer?.phoneNumber}
                />
              </FormControl>
            </Grid>

            <h5 className="mb-3 mt-3">Ticket Info</h5>

            <Grid>
              <FormControl
                style={{
                  minWidth: "20rem",
                  marginRight: "10px",
                  marginBottom: "10px",
                }}
              >
                <InputLabel id="demo-simple-select-helper-label">
                  Select Status
                </InputLabel>
                <Select
                  name="TicketStatus"
                  id="outlined-adornment-email"
                  label="Select Status"
                  onChange={(e, val: any) => {
                    if (val.props.value == "") {
                      return;
                    }
                    handleInputChange(e);
                  }}
                  onClose={(e) => {
                    document.body.style.overflow = "visible";
                  }}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>

                  {["Open", "Closed", "InProgress"].map((e: any) => (
                    <MenuItem value={e}>{e}</MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl
                style={{
                  minWidth: "20rem",
                  marginRight: "10px",
                  marginBottom: "10px",
                }}
              >
                <InputLabel id="demo-simple-select-helper-label">
                  Select Priority
                </InputLabel>
                <Select
                  name="TicketPriority"
                  id="outlined-adornment-email"
                  label="Select Priority"
                  style={{ display: "flex", alignItems: "center" }}
                  onChange={(e, val: any) => {
                    if (val.props.value == "") {
                      return;
                    }
                    handleInputChange(e);
                  }}
                  onClose={(e) => {
                    document.body.style.overflow = "visible";
                  }}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>

                  {[
                    {
                      label: "High",
                      icon: <Circle style={{ color: "#FF0000" }} />,
                    },
                    {
                      label: "Medium",
                      icon: <Circle style={{ color: "#FFF000" }} />,
                    },
                    {
                      label: "Low",
                      icon: <Circle style={{ color: "#0000FF" }} />,
                    },
                  ].map((item) => (
                    <MenuItem
                      value={item.label}
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      {/* <span className="mr-2">{item.icon}</span>&nbsp; */}
                      {item.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <FormControl
                style={{
                  minWidth: "20rem",
                  marginRight: "10px",
                  marginBottom: "10px",
                }}
              >
                <InputLabel id="demo-simple-select-helper-label">
                  Assine To
                </InputLabel>
                <Select
                  name="TicketAssine"
                  id="outlined-adornment-email"
                  label="Assine To"
                  onChange={(e, val: any) => {
                    if (val.props.value == "") {
                      return;
                    }
                    handleInputChange(e);
                  }}
                  onClose={(e) => {
                    document.body.style.overflow = "visible";
                  }}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>

                  {[
                    "Smit More",
                    "Chetan Mittal",
                    "Siddhartha Ghosal",
                    "KV Subrahmanyam",
                  ].map((e: any, i: any) => (
                    <MenuItem value={i + 1}>{e}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Col className="mb-2">
              <FormControl sx={{ mr: 1, minWidth: "20rem" }}>
                <InputLabel id="demo-simple-select-helper-label">
                  Select Country
                </InputLabel>
                <Select
                  name="Country"
                  labelId="demo-simple-select-helper-label"
                  id="demo-simple-select-helper"
                  // value={scoreType}
                  label="Select Country"
                  onChange={(e: any) => {
                    handleInputChange(e);

                    getStatesApi(e.target.value);
                  }}
                  onClose={(e) => {
                    document.body.style.overflow = "visible";
                  }}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {countries.map((e: any, i: any) => {
                    return (
                      <MenuItem key={e.id} value={e.id}>
                        {e.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>

              <FormControl sx={{ mr: 1, minWidth: "20rem" }}>
                <InputLabel id="demo-simple-select-helper-label">
                  Select State
                </InputLabel>
                <Select
                  name="State"
                  labelId="demo-simple-select-helper-label"
                  id="demo-simple-select-helper"
                  // value={scoreType}
                  label="Select State"
                  // onChange={handleChange}
                  onClose={(e) => {
                    document.body.style.overflow = "visible";
                  }}
                  onChange={(e) => {
                    handleInputChange(e);

                    getCitiesApi(e.target.value);
                  }}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {states.map((e: any) => {
                    return (
                      <MenuItem key={e.id} value={e.id}>
                        {e.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>

              <FormControl sx={{ mr: 1, minWidth: "20rem" }}>
                <InputLabel id="demo-simple-select-helper-label">
                  Select City
                </InputLabel>
                <Select
                  name="City"
                  labelId="demo-simple-select-helper-label"
                  id="demo-simple-select-helper"
                  label="Select City"
                  onClose={(e) => {
                    document.body.style.overflow = "visible";
                  }}
                  onChange={(e) => {
                    // let cityD: any = cities.find(
                    //   (p: any) => p.name === e.target.value
                    // );

                    handleInputChange(e);
                  }}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {cities.map((e: any) => {
                    return (
                      <MenuItem key={e.id} value={e.id}>
                        {e.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Col>

            <div>
              <ReactQuill theme="snow" value={value} onChange={setValue} />
            </div>
            <Col md="2" style={{ alignSelf: "center", paddingBottom: "1rem" }}>
              <Button
                variant="outlined"
                className=""
                color="success"
                type="button"
                name="textValue"
                onClick={() => {
                  let apiData = {
                    CustomerID: customer?.id,
                    IssueDescription: value,
                    TicketStatus: inpuValue["TicketStatus"],
                    Priority: inpuValue["TicketPriority"],
                    TicketTypeID: inpuValue["TicketType"],
                    CountryID: inpuValue["Country"],
                    StateID: inpuValue["State"],
                    CityID: inpuValue["City"],
                    OpenAt: new Date(),
                    ResolvedAt: "",
                    ResolveTime: "",
                    CreatedBy: "9",
                    AssignedTo: inpuValue["TicketAssine"],
                    UpdatedBy: null,
                    CreatedAt: new Date(),
                    UpdatedAt: new Date(),
                  };
                  console.log(createTicket);

                  createTicket(apiData);
                }}
              >
                Submit
              </Button>
            </Col>
          </>
        )}
      </Card>

      <FullScreenLoader isLoading={isLoading} />
    </Container>
  );
};

export default CCreateTicket;
