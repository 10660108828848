import React, { createContext, useState, useContext, useEffect } from "react";

export interface User {
  role: string;
  company_type: string;
}

interface AuthContextType {
  isAuthenticated: any;
  user: User | null;
  login: (accessToken: string, user: User) => void;
  logout: () => void;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const useAuth = (): AuthContextType => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};

interface AuthP {
  children: any;
}

export const AuthProvider: React.FC<AuthP> = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState<any>(null);
  const [user, setUser] = useState<User | null>(null);

  useEffect(() => {
    authenticate();
  }, []);

  const authenticate = () => {
    const accessToken = localStorage.getItem("accessToken");
    const company_type = localStorage.getItem("company_type");
    const role = localStorage.getItem("role");

    if (company_type != null && role != null) {
      const config: User = {
        role: role,
        company_type: company_type,
      };
      setUser(config);
    } else {
      setUser(null);
    }

    if (accessToken) {
      setIsAuthenticated(true);
    } else {
      setIsAuthenticated(false);
    }
  };

  const login = (accessToken: string, user: User): void => {
    localStorage.setItem("accessToken", accessToken);
    localStorage.setItem("company_type", user.company_type);
    localStorage.setItem("role", user.role);
    setUser(user);
    setIsAuthenticated(true);
  };

  const logout = (): void => {
    localStorage.removeItem("accessToken");
    setUser(null);
    setIsAuthenticated(false);
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, login, logout, user }}>
      {children}
    </AuthContext.Provider>
  );
};
