import PropTypes from "prop-types";
import {
  Avatar,
  Card,
  CardContent,
  Stack,
  SvgIcon,
  Typography,
} from "@mui/material";
import UsersIcon from "@heroicons/react/24/solid/UsersIcon";

export const OverviewBudget = (props) => {
  const {
    difference,
    positive = false,
    sx,
    value,
    text,
    subtext,
    color,
  } = props;

  return (
    <Card sx={sx} style={{ background: color }}>
      <CardContent>
        <Stack
          alignItems="flex-start"
          direction="row"
          justifyContent="space-between"
          spacing={3}
        >
          <Stack spacing={1}>
            <Typography
              style={{ fontWeight: 900, color: "white" }}
              color="text.secondary"
              variant="overline"
            >
              {text}
            </Typography>
            <Typography
              style={{ fontWeight: 700, color: "white" }}
              variant="h4"
            >
              {value}
            </Typography>
          </Stack>
        </Stack>
        {difference && (
          <Stack alignItems="center" direction="row" spacing={2} sx={{ mt: 2 }}>
            <Typography
              color="text.secondary"
              variant="caption"
              style={{ fontWeight: 900, color: "white" }}
            >
              {subtext}
            </Typography>
          </Stack>
        )}
      </CardContent>
    </Card>
  );
};

OverviewBudget.prototypes = {
  difference: PropTypes.number,
  positive: PropTypes.bool,
  sx: PropTypes.object,
  value: PropTypes.string.isRequired,
  text: PropTypes.string,
  subtext: PropTypes.string,
};
