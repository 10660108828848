import React, { useEffect, useRef, useState } from "react";
import axiosInstance from "../../../utils/AxiosInstance";
import urlConfigs from "../../../configs/urlCongfig";
import { Card, Container, Nav, Navbar } from "reactstrap";
import { MaterialReactTable } from "material-react-table";
import { CSVLink } from "react-csv";
import { Box } from "@mui/material";
import FileUploadModal from "../../../components/common/FileUploadModal";
import FullScreenLoader from "../../../components/Loaders/FullScreenLoader";
// import ConfirmationAlertModal from "../../components/common/ConfirmationAlertModal";

type Props = {};

const PUploadRecordScreen = (props: Props) => {
  const [isLoading, setIsLoading] = useState(false);

  const [openModal, setOpenModal] = useState(false);

  const keyToShow: any = {
    index: "#",
    FileName: "File Name",
    Sxtatus: "Job Status",
    SuccessRecords: "Success Records",
    FailRecords: "Fail Records",
    CreatedAt: "Uploaded Date",
  };

  const exampleData = [
    {
      "Record#": "1",
      "Customer Id": "A13",
      "Upload Date": "6/3/23",
      "Customer Name/ ID": "john deo",
      "Phone Number": "1234567890",
      email: "test@example.com",
      Age: "35",
      Location: "xyz",
      Gender: "M",
      Height: "175",
      Weight: "83",
      BMI: "26",
      "Blood Group": "A+",
      "Nature of Job": "Desk Job",
      "Activity Level": "Moderate",
      "Exercise Level": "Walking",
      "Eating Habits": "Vegan",
      "Sleep Duration": "5",
      "Any Surgical History?": "No",
      "Any Medical History?": "Yes",
      Smoking: "Yes",
      Drinking: "Yes",
      "Family Medical History?": "Yes",
      "Changes in eating habits?": "No",
      "Changes in sleeping pattern?": "No",
      "Any stress?": "No",
      "Worry a lot?": "No",
      "Confident?": "Yes",
      "Need support?": "Yes",
      "Enjoy community?": "No",
      "Is your stress level high?": "No",
      "Do you always have cold/ cough?": "No",
      "Do you suffer from frequent stomach upsets?": "No",
      "Does your wounds heal slowly?": "Yes",
      "Do you get frequent infections?": "No",
    },
  ];

  const tableHeaderData: any = Object.keys(keyToShow).map((key) => {
    if (key === "SuccessRecords") {
      return {
        accessor: key,
        accessorKey: key,
        enableColumnFilterModes: false,
        filterFn: "equals",
        header: keyToShow[key],
        Cell: ({ cell }: any) => (
          <Box
            component="span"
            sx={(theme) => ({
              backgroundColor: theme.palette.success.dark,
              borderRadius: "0.25rem",
              color: "#fff",
              width: "8rem",
              display: "block",
              textAlign: "center",
              pt: "0.50rem",
              pb: "0.50rem",
            })}
          >
            {cell.getValue()}
          </Box>
        ),
      };
    }
    if (key === "FailRecords") {
      return {
        accessor: key,
        accessorKey: key,
        enableColumnFilterModes: false,
        filterFn: "equals",
        header: keyToShow[key],
        Cell: ({ cell, row }: any) => (
          <Box
            onClick={() => {}}
            component="span"
            sx={(theme) => ({
              backgroundColor: theme.palette.error.dark,
              borderRadius: "0.25rem",
              color: "#fff",
              width: "8rem",
              display: "block",
              textAlign: "center",
              pt: "0.50rem",
              pb: "0.50rem",
            })}
          >
            {cell.getValue()}
          </Box>
        ),
      };
    }
    return {
      accessor: key,
      accessorKey: key,
      enableColumnFilterModes: false,
      filterFn: "equals",
      header: keyToShow[key],
    };
  });

  const [jobList, setJobList] = useState([]);
  const isMounted = useRef(false);

  useEffect(() => {
    if (!isMounted.current) {
      getOverView();
    }

    isMounted.current = true;
  }, []);

  const getOverView = () => {
    setIsLoading(true);
    axiosInstance
      .get(urlConfigs.FILE_LIST)
      .then((response) => {
        response.data.forEach((element: any, i: any) => {
          element.index = i + 1;
          element.CreatedAt = new Date(element.CreatedAt).toLocaleDateString();
        });
        setJobList(response.data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
      });
  };

  return (
    <Container fluid className="vh-100">
      <Navbar className="mt-2 mb-2" color="light">
        <Nav className="me-auto" navbar></Nav>
        <div
          className="create-btn"
          onClick={() => {
            setOpenModal(true);
          }}
          style={{
            textDecoration: "none",
            background: "#212529",
            marginRight: "1rem",
          }}
        >
          Upload CSV
        </div>
        <div
          className="create-btn ml-3"
          style={{ textDecoration: "none", background: "#212529" }}
        >
          <CSVLink
            data={exampleData}
            headers={Object.keys(exampleData[0]).map((key: any) => key)}
            style={{ color: "white", textDecoration: "none" }}
            onClick={(e: any) => e.stopPropagation()}
            filename={"example.csv"}
          >
            Download CSV
          </CSVLink>
        </div>
      </Navbar>
      <Card
        style={{ borderRadius: 10 }}
        className="shadow p-3 pt-4 mb-5 bg-white "
      >
        <MaterialReactTable
          muiTableHeadCellProps={{
            sx: (theme) => ({
              background: "#faf4d4",
              color: theme.palette.text.primary,
            }),
          }}
          muiTableBodyRowProps={{
            sx: {
              backgroundColor: "rgb(228, 241, 221)",
              borderRight: "1px solid rgba(224,224,224,1)",
            },
          }}
          muiTopToolbarProps={{
            sx: (theme) => ({
              background: "#faf4d4",
              color: theme.palette.text.primary,
            }),
          }}
          muiBottomToolbarProps={{
            sx: (theme) => ({
              background: "#faf4d4",
              color: theme.palette.text.primary,
            }),
          }}
          columns={tableHeaderData}
          data={jobList}
          columnResizeMode="onChange"
          initialState={{ showColumnFilters: false }} //show filters by default
          filterFns={{
            customFilterFn: (row, id, filterValue) => {
              return row.getValue(id) === filterValue;
            },
          }}
        />
      </Card>

      <FileUploadModal
        isOpen={openModal}
        onClose={() => {
          setOpenModal(false);
        }}
        onConfirm={() => {
          setOpenModal(false);
          getOverView();
        }}
        isReport={false}
        triggerLoader={(loading: boolean) => {
          setIsLoading(loading);
        }}
      />
      <FullScreenLoader isLoading={isLoading} />
    </Container>
  );
};

export default PUploadRecordScreen;
