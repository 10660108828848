import React, { useEffect, useRef, useState } from "react";
import { Card, Container, Nav, Navbar } from "reactstrap";
import { MaterialReactTable } from "material-react-table";
import FullScreenLoader from "../../../components/Loaders/FullScreenLoader";
import urlConfigs from "../../../configs/urlCongfig";
import axiosInstance from "../../../utils/AxiosInstance";
import HospitalDetailsModal from "../../../components/common/HospitalDetailsModal";

type Props = {};

const HHospitalDetailsPage = (props: Props) => {
  const [isLoading, setIsLoading] = useState(false);

  const [openModal, setOpenModal] = useState(false);

  const fieldShow = [
    { label: "#", key: "index" },
    { label: "Hospital Code", key: "HospitalCode" },
    { label: "Hospital Name", key: "HospitalName" },
    { label: "Address", key: "Address" },
    { label: "Phone Number", key: "PhoneNumber" },
    { label: "Pin Code", key: "PinCode" },
    { label: "Speciality", key: "Speciality" },
    { label: "System Of Medication", key: "SystemOfMedication" },
    { label: "Capacity", key: "Capacity" },
    { label: "Admissions", key: "Admissions" },
    { label: "Doctor Utilization", key: "DoctorUtilization" },
    {
      label: "Hospitalization Based On Disease",
      key: "HospitalizationBasedOnDisease",
    },
    { label: "OPD To IPD", key: "OPDToIPD" },
    { label: "PHI Consent", key: "PHIConsent" },
    { label: "Drugs Prescribed", key: "DrugsPrescribed" },
  ];

  const tableHeaderData: any = fieldShow.map((key) => {
    return {
      accessor: key.key,
      accessorKey: key.key,
      enableColumnFilterModes: false,
      filterFn: "equals",
      header: key.label,
      accessorFn: (data: any) => data[key.key].toString() || "Not Provided",
    };
  });

  const [jobList, setJobList] = useState([]);
  const isMounted = useRef(false);

  useEffect(() => {
    if (!isMounted.current) {
      getOverView();
    }

    isMounted.current = true;
  }, []);

  const getOverView = () => {
    setIsLoading(true);
    axiosInstance
      .get(urlConfigs.hospital)
      .then((response) => {
        response.data.hospitalList.forEach((element: any, i: any) => {
          element.index = i + 1;
          // element.CreatedAt = new Date(element.CreatedAt).toLocaleDateString();
        });
        setJobList(response.data.hospitalList);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
      });
  };

  return (
    <Container fluid className="vh-100">
      <Navbar className="mt-2 mb-2" color="light">
        <Nav className="me-auto" navbar></Nav>
        <div
          className="create-btn"
          onClick={() => {
            setOpenModal(true);
          }}
          style={{
            textDecoration: "none",
            background: "#212529",
            marginRight: "1rem",
          }}
        >
          Create Hospital
        </div>
      </Navbar>
      <Card
        style={{ borderRadius: 10 }}
        className="shadow p-3 pt-4 mb-5 bg-white "
      >
        <MaterialReactTable
          muiTableHeadCellProps={{
            sx: (theme) => ({
              background: "#faf4d4",
              color: theme.palette.text.primary,
            }),
          }}
          muiTableBodyRowProps={{
            sx: {
              backgroundColor: "rgb(228, 241, 221)",
              borderRight: "1px solid rgba(224,224,224,1)",
            },
          }}
          muiTopToolbarProps={{
            sx: (theme) => ({
              background: "#faf4d4",
              color: theme.palette.text.primary,
            }),
          }}
          muiBottomToolbarProps={{
            sx: (theme) => ({
              background: "#faf4d4",
              color: theme.palette.text.primary,
            }),
          }}
          columns={tableHeaderData}
          data={jobList}
          columnResizeMode="onChange"
          initialState={{ showColumnFilters: false }} //show filters by default
          filterFns={{
            customFilterFn: (row, id, filterValue) => {
              return row.getValue(id) === filterValue;
            },
          }}
        />
      </Card>

      <HospitalDetailsModal
        isOpen={openModal}
        onClose={() => {
          setOpenModal(false);
        }}
        onConfirm={() => {
          setOpenModal(false);
          getOverView();
        }}
        isReport={false}
        triggerLoader={(loading: boolean) => {
          setIsLoading(loading);
        }}
      />
      <FullScreenLoader isLoading={isLoading} />
    </Container>
  );
};

export default HHospitalDetailsPage;
