import React, { useEffect, useRef, useState } from "react";
import { Card, Container, Nav, Navbar } from "reactstrap";
import { MaterialReactTable } from "material-react-table";
import FullScreenLoader from "../../../components/Loaders/FullScreenLoader";
import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import urlConfigs from "../../../configs/urlCongfig";
import axiosInstance from "../../../utils/AxiosInstance";
import moment from "moment";

type Props = {};

const CHomePage = (props: Props) => {
  const [isLoading, setIsLoading] = useState(false);

  const [fetchTickets, setFetchTickets] = useState<any>([]);
  const isMounted = useRef(false);

  const fieldShow = [
    { label: "#", key: "index" },
    { label: "CustomerID", key: "CustomerID" },
    { label: "IssueDescription", key: "IssueDescription" },
    { label: "TicketStatus", key: "TicketStatus" },
    { label: "TicketTypeID", key: "TicketTypeID" },
    { label: "Priority", key: "Priority" },
    { label: "CreatedAt", key: "CreatedAt" },
    { label: "ResolveTime", key: "ResolveTime" },
  ];

  React.useEffect(() => {
    if (!isMounted.current) {
      getCustomerDetails();
    }

    isMounted.current = true;
  }, []);

  const tableHeaderData: any = fieldShow.map((key) => {
    if (key.key == "created_at") {
      return {
        accessor: key.key,
        accessorKey: key.key,
        enableColumnFilterModes: false,
        filterFn: "equals",
        header: key.label,
        accessorFn: (data: any) =>
          new Date(data[key.key]).toLocaleString() || "Not Provided",
      };
    }
    return {
      accessor: key.key,
      accessorKey: key.key,
      enableColumnFilterModes: false,
      filterFn: "equals",
      header: key.label,
      accessorFn: (data: any) => data[key.key]?.toString() || "Not Provided",
    };
  });

  const getCustomerDetails = () => {
    axiosInstance
      .get(urlConfigs.CALLCENTER)
      .then((response: any) => {
        response.data.ticketList.forEach((element: any, i: any) => {
          element.index = i + 1;
          element.CreatedAt = moment(element.CreatedAt).format(
            "DD-MM-YYYY HH:MM A"
          );
        });
        setFetchTickets(response.data.ticketList);
      })
      .catch((error: any) => {
        setIsLoading(false);
        console.error(error);
      });
  };

  return (
    <Container fluid className="vh-100" style={{ minWidth: "100%" }}>
      <Navbar className="mt-2 mb-2" color="light">
        <FormControl sx={{ minWidth: "40%" }}>
          <InputLabel id="demo-simple-select-helper-label">
            Select Volunteer
          </InputLabel>
          <Select
            name="select_volunteer"
            id="outlined-adornment-email"
            label="Select Volunteer"
            onChange={(e, val: any) => {
              if (val.props.value == "") {
                return;
              }
            }}
            onClose={(e) => {
              document.body.style.overflow = "visible";
            }}
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>

            {["Active", "Pending", "Complete"].map((e: any) => (
              <MenuItem value={e}>{e}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </Navbar>
      <Card
        style={{ borderRadius: 10 }}
        className="shadow p-3 pt-4 mb-5 bg-white "
      >
        <MaterialReactTable
          muiTableHeadCellProps={{
            sx: (theme) => ({
              background: "#faf4d4",
              color: theme.palette.text.primary,
            }),
          }}
          muiTableBodyRowProps={{
            sx: {
              backgroundColor: "rgb(228, 241, 221)",
              borderRight: "1px solid rgba(224,224,224,1)",
            },
          }}
          muiTopToolbarProps={{
            sx: (theme) => ({
              background: "#faf4d4",
              color: theme.palette.text.primary,
            }),
          }}
          muiBottomToolbarProps={{
            sx: (theme) => ({
              background: "#faf4d4",
              color: theme.palette.text.primary,
            }),
          }}
          columns={tableHeaderData}
          data={fetchTickets}
          columnResizeMode="onChange"
          initialState={{ showColumnFilters: false }} //show filters by default
          filterFns={{
            customFilterFn: (row, id, filterValue) => {
              return row.getValue(id) === filterValue;
            },
          }}
        />
      </Card>

      <FullScreenLoader isLoading={isLoading} />
    </Container>
  );
};

export default CHomePage;
