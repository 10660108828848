const urlConfigs = {
  LOGIN: `/auth/login`,
  SIGNUP: `User/sign_up`,
  FILE_LIST: "/file",
  REPORT_UPLOAD: "/report",
  HEALTHCARE: "/report/healthCare",
  PREDICTIVEANALYTICSREPORT: "/report/predictiveAnalyticsReport",
  REGULATORYCOMPLIANCE: "/report/healthCare",
  RISKASSESSMENT: "/report/risk",
  CUSTOMIZEDPREMIUMSREPORT: "/report/customizedPremiumsReport",
  CUSTOMER_LIST: "/customer/list",
  CUSTOMER_PROFILE: "/customer/profile",
  CUSTOMER_PROGRESS: "/customer/profile/progress",
  CAMPAIGN: "/customer/campaign",
  PROGRESS_REPORT: "/customer/progress",
  BEHAVIOR_REPORT: "/customer/behavior",
  GOALS_REPORT: "/customer/goals",
  FILE_UPLOAD: `User/file_upload`,
  LIST_JOBS: `User/list_jobs`,
  JOB_DETAILS: `User/job_details`,
  HEALTHASSESSMENT: `profileReport/healthAssessment`,
  SYMPTOMTRENDREPORT: `profileReport/symptomTrendReport`,
  CUSTOMERSYMPTOMTRENDREPORT: "profileReport/customerSymptomTrendReport",
  bloodGroupDistributionChart: "profileReport/bloodGroupDistributionChart",
  happinessQuotientChart: "profileReport/happinessQuotientChart",
  insuranceCover: "profileReport/insuranceCover",
  environmentFactorCharts: "profileReport/environmentFactorCharts",
  healthConditionHeatMap: "profileReport/healthConditionHeatMap",
  sicknessVsWellnessChart: "profileReport/sicknessVsWellnessChart",
  weatherChangeFactorGraph: "profileReport/weatherChangeFactor",
  hospital: "hospital",
  checkCustomer: "/gov/checkCustomer",
  gov: "/gov",
  VOLUNTEER: "/volunteer",
  CALLCENTER: "/call/tickets",
  INSIGHTS: "/insights",
};

export default urlConfigs;
