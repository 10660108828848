import {
  Box,
  Card,
  CardContent,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
} from "@mui/material";
import React, { ChangeEvent, useEffect, useState } from "react";
import { Row, Col, Button, FormGroup } from "reactstrap";
import formData from "./formData.json";
import hygieneData from "./hygieneData.json";
import healthData from "./health.json";
import urlConfigs from "../../../configs/urlCongfig";
import axiosInstance from "../../../utils/AxiosInstance";
import { UserDetailsInterface } from "./customerDetailInterface";
import FullScreenLoader from "../../../components/Loaders/FullScreenLoader";

function GovSingleForm() {
  const [gender, setGender] = useState("");
  const [language, setLanguage] = useState("English");
  const [age, setAge] = useState<number>(0);
  const [dynamicForm, setDynamicForm] = useState<any[]>([]);
  const [hygieneDynamicForm, setHygieneDynamicForm] = useState<any[]>([]);
  const [isPregnant, setIsPregnant] = useState("");
  const [patientData, setPatientData] = useState<any>({});
  const [isUser, setIsUser] = useState(false);
  const [isUserData, setIsUserData] = useState<UserDetailsInterface | null>(
    null
  );
  const [isLoading, setIsLoading] = useState(false);

  const [mobileData, setMobile] = useState("");
  const [userList, setUserList] = useState<any[]>([]);
  const [disableField, setDisableField] = useState<any>(false);

  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      setSelectedFile(event.target.files[0]);
    }
  };

  const [formSubmitData, setFormSubmitData] = useState<any>({
    name: "",
    CustomerId: 0,
    age: "",
    phoneNumber: "",
    PHCId: 0,
    gender: "",
    familySeq: 0,
    customerName: "",
    educationLevel: "",
    maritalStatus: "",
    weight: "",
    height: "",
    BMI: "",
    marriedAtAge: 0,
    children: 0,
    abortions: 0,
    miscarriages: 0,
    firstChildAge: 0,
    birthInterval: 0,
    armCircumferenceCms: "",
    FFTMM: 0,
    incomeGroup: "",
    pregnant: 0,
    aadharCardID: "",
    electionID: "",
    PANID: "",
    email: "",
    powerUser: 0,
  });

  const [location, setLocation] = useState<any>(null);
  const [error, setError] = useState<any>(null);

  useEffect(() => {
    // Check if the Geolocation API is available
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          // Get the latitude and longitude
          const { latitude, longitude } = position.coords;
          console.log(position);
          setLocation({ latitude, longitude });
        },
        (err) => {
          setError(err.message);
        }
      );
    } else {
      setError("Geolocation is not supported in this browser.");
    }
  }, []);

  const setAgeData = () => {
    if (age < 14) {
      setIsPregnant("false");
    }
  };

  const refreshPage = () => {
    setGender("");
    setAge(0);
    setDynamicForm([]);
    setHygieneDynamicForm([]);
    setIsPregnant("");
    setIsUserData(null);
    setMobile("");
    setUserList([]);
    setIsUser(false);
    setDisableField(false);
    setFormSubmitData({
      name: "",
      CustomerId: 0,
      age: "",
      phoneNumber: "",
      PHCId: 0,
      gender: "",
      familySeq: 0,
      customerName: "",
      educationLevel: "",
      maritalStatus: "",
      weight: "",
      height: "",
      BMI: "",
      marriedAtAge: 0,
      children: 0,
      abortions: 0,
      miscarriages: 0,
      firstChildAge: 0,
      birthInterval: 0,
      armCircumferenceCms: "",
      FFTMM: 0,
      incomeGroup: "",
      pregnant: 0,
      aadharCardID: "",
      electionID: "",
      PANID: "",
      email: "",
      powerUser: 0,
    });
  };

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;

    setFormSubmitData({
      ...formSubmitData,
      [name]: value,
    });
  };

  useEffect(() => {
    setFormData();
  }, [language]);

  useEffect(() => {
    setFormData();
  }, [gender]);

  useEffect(() => {
    setFormData();
  }, [isUser]);

  useEffect(() => {
    getUserData();
  }, [mobileData]);

  const handleUpload = (id: any) => {
    console.log(selectedFile);
    setIsLoading(false);

    if (selectedFile) {
      console.log("adshjbchjsdbcj");
      
      const formData = new FormData();
      formData.append("report", selectedFile);
      formData.append("customerId", id);

      axiosInstance
        .post(urlConfigs.REPORT_UPLOAD, formData)
        .then((response) => {
          setIsLoading(false);
          console.log("File uploaded successfully");
          refreshPage();
          // Handle success response
        })
        .catch((error) => {
          setIsLoading(false);
          console.error("Error uploading file:", error);
          // Handle error response
        });
    }else{
      refreshPage();
    }
  };

  const setFormData = () => {
    if (gender === "female" && age > 14) {
      if (isPregnant === "true") {
        if (language === "English") {
          setDynamicForm(formData.pregnantFemale.en);
          setHygieneDynamicForm(hygieneData.female.en);
        } else {
          setDynamicForm(formData.pregnantFemale.mr);
          setHygieneDynamicForm(hygieneData.female.mr);
        }
      } else {
        if (language === "English") {
          setDynamicForm(formData.female.en);
          setHygieneDynamicForm(hygieneData.female.en);
        } else {
          setDynamicForm(formData.female.mr);
          setHygieneDynamicForm(hygieneData.female.mr);
        }
      }
    } else if (gender === "male" && age > 14) {
      if (language === "English") {
        setDynamicForm(formData.male.en);
        setHygieneDynamicForm(hygieneData.all.en);
      } else {
        setDynamicForm(formData.male.mr);
        setHygieneDynamicForm(hygieneData.all.mr);
      }
      setIsPregnant("false");
    } else if (age < 14) {
      if (language === "English") {
        setDynamicForm(formData.kids.en);
        setHygieneDynamicForm(hygieneData.all.en);
      } else {
        setDynamicForm(formData.kids.mr);
        setHygieneDynamicForm(hygieneData.all.mr);
      }
      setIsPregnant("false");
    }
  };

  const getUserData = () => {
    if (mobileData.length == 10) {
      setUserList([]);
      axiosInstance
        .post(urlConfigs.checkCustomer, { phoneNumber: mobileData })
        .then((response) => {
          setUserList(response.data.hospitalList);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  const updateRecord = (data: any) => {
    if (mobileData.length == 10) {
      setIsLoading(true);
      setUserList([]);
      axiosInstance
        .post(urlConfigs.gov, { ...data })
        .then((response) => {
          console.log(response);
          if (response?.data?.customer?.id != null) {
            
            handleUpload(response.data.customer.id);
          }
        })
        .catch((error) => {
          setIsLoading(false);
          console.error(error);
        });
    }
  };

  return (
    <>
      <form autoComplete="off" action="">
        <Card>
          <CardContent>
            <Row className="mt-4">
              <Col md="4">
                <Box sx={{ display: "flex", flexWrap: "wrap" }}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      Language
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      name="language"
                      label="language"
                      value={language}
                      onChange={(e: any) => setLanguage(e.target.value)}
                    >
                      <MenuItem value={"English"}>English</MenuItem>
                      <MenuItem value={"Marathi"}>Marathi</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
              </Col>
              <Col
                md="2"
                style={{ alignSelf: "center", paddingBottom: "1rem" }}
              >
                <Button
                  variant="outlined"
                  className=""
                  color="success"
                  type="button"
                  onClick={() => {
                    refreshPage();
                  }}
                >
                  Refresh
                </Button>
              </Col>
            </Row>
            <Row className="mt-4">
              <Col md="4" className="mb-3">
                <Box sx={{ display: "flex", flexWrap: "wrap" }}>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel htmlFor="mobile">
                      {language === "English" ? "Mobile No." : "मोबाईल क्र"}
                    </InputLabel>
                    <OutlinedInput
                      key={"mobile"}
                      value={mobileData}
                      onChange={(e) => {
                        handleInputChange(e);
                        setMobile(e.target.value);
                      }}
                      name="phoneNumber"
                      disabled={disableField}
                      id={"mobile"}
                      label={
                        language === "English" ? "Mobile No." : "मोबाईल क्र"
                      }
                    />
                  </FormControl>
                </Box>
              </Col>
              {userList.length == 0 ? (
                <></>
              ) : (
                <Col md="4" className="mb-3">
                  <Box sx={{ display: "flex", flexWrap: "wrap" }}>
                    <FormControl fullWidth>
                      <InputLabel id="user">
                        {language === "English" ? "Select user" : "Select user"}
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="user"
                        name="user"
                        disabled={disableField}
                        onChange={(val) => {
                          const data = userList.find(
                            (e) => e.id == val.target.value
                          );
                          setFormSubmitData({
                            ...data.gov_details,
                            name: data.name,
                            age: data.age,
                          });
                        }}
                        label={
                          language === "English" ? "Select user" : "Select user"
                        }
                      >
                        {userList.map((x) => (
                          <MenuItem value={x.id}>{x.name}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
                </Col>
              )}

              <Col
                md="2"
                style={{ alignSelf: "center", paddingBottom: "1rem" }}
              >
                <Button
                  variant="outlined"
                  className=""
                  color="success"
                  type="button"
                  onClick={() => {
                    if (userList.length == 0) {
                      setIsUserData(null);
                    }
                    setDisableField(true);
                    setIsUser(true);
                  }}
                >
                  Submit
                </Button>
              </Col>
            </Row>

            {isUser ? (
              <>
                <Row className="mt-4">
                  <Col md="4" className="mb-3">
                    <Box sx={{ display: "flex", flexWrap: "wrap" }}>
                      <FormControl fullWidth key="name" variant="outlined">
                        <InputLabel htmlFor="name">
                          {language === "English" ? "Name" : "नाव"}
                        </InputLabel>
                        <OutlinedInput
                          value={formSubmitData.name}
                          name="name"
                          onChange={handleInputChange}
                          id={"name"}
                          label={language === "English" ? "Name" : "नाव"}
                        />
                      </FormControl>
                    </Box>
                  </Col>

                  <Col md="4" className="mb-3">
                    <Box sx={{ display: "flex", flexWrap: "wrap" }}>
                      <FormControl fullWidth key="age" variant="outlined">
                        <InputLabel htmlFor="age">
                          {language === "English" ? "Age" : "वय"}
                        </InputLabel>
                        <OutlinedInput
                          onChange={(e: any) => {
                            setAge(e.target.value);
                            setAgeData();
                            handleInputChange(e);
                          }}
                          name="age"
                          id={"age"}
                          value={formSubmitData.age}
                          label={"age"}
                        />
                      </FormControl>
                    </Box>
                  </Col>
                  {/* <Col md="4" className="mb-3">
                      <Box sx={{ display: "flex", flexWrap: "wrap" }}>
                        <FormControl fullWidth  variant="outlined">
                          <InputLabel htmlFor="Email">
                            {language === "English" ? "Email" : "ईमेल"}
                          </InputLabel>
                          <OutlinedInput
                            key={"Email"}
                            id={"Email"}
                            label={language === "English" ? "Email" : "ईमेल"}
                          />
                        </FormControl>
                      </Box>
                    </Col> */}
                  <Col md="4" className="mb-3">
                    <Box sx={{ display: "flex", flexWrap: "wrap" }}>
                      <FormControl fullWidth key="gender">
                        <InputLabel id="demo-simple-select-label">
                          {language === "English" ? "Gender" : "लिंग"}
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          label="gender"
                          value={formSubmitData.gender}
                          onChange={(e: any) => {
                            setGender(e.target.value);
                            handleInputChange(e);
                          }}
                          name="gender"
                        >
                          <MenuItem value={"male"}>
                            {language === "English" ? "Male" : "पुरुष"}
                          </MenuItem>
                          <MenuItem value={"female"}>
                            {language === "English" ? "Female" : "स्त्री"}
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </Box>
                  </Col>

                  {gender === "female" && age > 14 ? (
                    <>
                      <Col md="4" className="mb-3">
                        <Box sx={{ display: "flex", flexWrap: "wrap" }}>
                          <FormControl fullWidth key="pregnant">
                            <InputLabel id="is_Pregnant">
                              {language === "English"
                                ? "is Pregnant"
                                : "गर्भवती"}
                            </InputLabel>
                            <Select
                              labelId="is_Pregnant"
                              id="is_Pregnant"
                              label="is Pregnant"
                              name="pregnant"
                              value={formSubmitData?.pregnant}
                              onChange={(e: any) => {
                                setIsPregnant(e.target.value);
                                handleInputChange(e);
                              }}
                            >
                              <MenuItem value={"false"}>
                                {language === "English" ? "False" : "नाही"}
                              </MenuItem>
                              <MenuItem value={"true"}>
                                {language === "English" ? "True" : "होय"}
                              </MenuItem>
                            </Select>
                          </FormControl>
                        </Box>
                      </Col>
                      <Col md="4" className="mb-3">
                        <Box sx={{ display: "flex", flexWrap: "wrap" }}>
                          <FormControl
                            fullWidth
                            variant="outlined"
                            key="abortions"
                          >
                            <InputLabel htmlFor="Abortions">
                              {language === "English" ? "Abortions" : "गर्भपात"}
                            </InputLabel>
                            <OutlinedInput
                              key={"Abortions"}
                              value={formSubmitData?.abortions}
                              id={"Abortions"}
                              name="abortions"
                              onChange={handleInputChange}
                              label={
                                language === "English" ? "Abortions" : "गर्भपात"
                              }
                            />
                          </FormControl>
                        </Box>
                      </Col>
                      <Col md="4" className="mb-3">
                        <Box sx={{ display: "flex", flexWrap: "wrap" }}>
                          <FormControl fullWidth variant="outlined">
                            <InputLabel htmlFor="Miscarriages">
                              {language === "English"
                                ? "Miscarriages"
                                : "अपघाती गर्भपात"}
                            </InputLabel>
                            <OutlinedInput
                              key={"Miscarriages"}
                              id={"Miscarriages"}
                              name="miscarriages"
                              value={formSubmitData?.miscarriages}
                              onChange={handleInputChange}
                              label={
                                language === "English"
                                  ? "Miscarriages"
                                  : "अपघाती गर्भपात"
                              }
                            />
                          </FormControl>
                        </Box>
                      </Col>
                      <Col md="4" className="mb-3">
                        <Box sx={{ display: "flex", flexWrap: "wrap" }}>
                          <FormControl fullWidth variant="outlined">
                            <InputLabel htmlFor="FirstChildAge">
                              {language === "English"
                                ? "First Child Age"
                                : "पहिलं बाळाचं वय"}
                            </InputLabel>
                            <OutlinedInput
                              key={"FirstChildAge"}
                              name="firstChildAge"
                              value={formSubmitData?.firstChildAge}
                              onChange={handleInputChange}
                              id={"FirstChildAge"}
                              label={
                                language === "English"
                                  ? "First Child Age"
                                  : "पहिलं बाळाचं वय"
                              }
                            />
                          </FormControl>
                        </Box>
                      </Col>
                      <Col md="4" className="mb-3">
                        <Box sx={{ display: "flex", flexWrap: "wrap" }}>
                          <FormControl fullWidth variant="outlined">
                            <InputLabel htmlFor="BirthInterval">
                              {language === "English"
                                ? "Birth Interval"
                                : "जन्म अंतर"}
                            </InputLabel>
                            <OutlinedInput
                              key={"BirthInterval"}
                              name="birthInterval"
                              value={formSubmitData?.birthInterval}
                              onChange={handleInputChange}
                              id={"BirthInterval"}
                              label={
                                language === "English"
                                  ? "Birth Interval"
                                  : "जन्म अंतर"
                              }
                            />
                          </FormControl>
                        </Box>
                      </Col>
                    </>
                  ) : (
                    <></>
                  )}

                  <Col md="4" className="mb-3">
                    <Box sx={{ display: "flex", flexWrap: "wrap" }}>
                      <FormControl fullWidth variant="outlined">
                        <InputLabel htmlFor="AadharCardID">
                          {language === "English" ? "Adhaar No." : "आधार क्र"}
                        </InputLabel>
                        <OutlinedInput
                          key={"AadharCardID"}
                          value={formSubmitData?.aadharCardID}
                          name="aadharCardID"
                          onChange={handleInputChange}
                          id={"AadharCardID"}
                          label={
                            language === "English" ? "Adhaar No." : "आधार क्र"
                          }
                        />
                      </FormControl>
                    </Box>
                  </Col>
                  <Col md="4" className="mb-3">
                    <Box sx={{ display: "flex", flexWrap: "wrap" }}>
                      <FormControl fullWidth variant="outlined">
                        <InputLabel htmlFor="ElectionID">
                          {language === "English"
                            ? "Election ID"
                            : "मतदान ओळखपत्र क्र"}
                        </InputLabel>
                        <OutlinedInput
                          key={"ElectionID"}
                          value={formSubmitData?.electionID}
                          name="electionID"
                          onChange={handleInputChange}
                          id={"ElectionID"}
                          label={
                            language === "English"
                              ? "Election ID"
                              : "मतदान ओळखपत्र क्र"
                          }
                        />
                      </FormControl>
                    </Box>
                  </Col>
                  <Col md="4" className="mb-3">
                    <Box sx={{ display: "flex", flexWrap: "wrap" }}>
                      <FormControl fullWidth variant="outlined">
                        <InputLabel htmlFor="PANID">
                          {language === "English" ? "PAN ID" : "पॅन क्र"}
                        </InputLabel>
                        <OutlinedInput
                          key={"PANID"}
                          name="PANID"
                          value={formSubmitData?.PANID}
                          onChange={handleInputChange}
                          id={"PANID"}
                          label={language === "English" ? "PAN ID" : "पॅन क्र"}
                        />
                      </FormControl>
                    </Box>
                  </Col>
                  <Col md="4" className="mb-3">
                    <Box sx={{ display: "flex", flexWrap: "wrap" }}>
                      <FormControl fullWidth variant="outlined">
                        <InputLabel htmlFor="EducationLevel">
                          {language === "English"
                            ? "Education Level"
                            : "शैक्षणिक पातळी"}
                        </InputLabel>
                        <OutlinedInput
                          key={"adhEducationLevelaar"}
                          onChange={handleInputChange}
                          name="educationLevel"
                          value={formSubmitData?.educationLevel}
                          id={"EducationLevel"}
                          label={
                            language === "English"
                              ? "Education Level"
                              : "शैक्षणिक पातळी"
                          }
                        />
                      </FormControl>
                    </Box>
                  </Col>
                  <Col md="4" className="mb-3">
                    <Box sx={{ display: "flex", flexWrap: "wrap" }}>
                      <FormControl fullWidth>
                        <InputLabel id="MaritalStatus">
                          {language === "English"
                            ? "Marital Status"
                            : "वैवाहिक स्थिती"}
                        </InputLabel>
                        <Select
                          labelId="MaritalStatus"
                          id="MaritalStatus"
                          label="Marital Status"
                          value={formSubmitData?.maritalStatus}
                          onChange={handleInputChange}
                          name="maritalStatus"
                        >
                          <MenuItem value={0}>
                            {language === "English" ? "Married" : "विवाहित"}
                          </MenuItem>
                          <MenuItem value={1}>
                            {language === "English" ? "Single" : "अविवाहित"}
                          </MenuItem>
                          <MenuItem value={2}>
                            {language === "English" ? "Divorced" : "घटस्फोटित"}
                          </MenuItem>
                          <MenuItem value={3}>
                            {language === "English" ? "Widowed" : "विधवा"}
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </Box>
                  </Col>
                  <Col md="4" className="mb-3">
                    <Box sx={{ display: "flex", flexWrap: "wrap" }}>
                      <FormControl fullWidth variant="outlined">
                        <InputLabel htmlFor="Weight">
                          {language === "English" ? "Weight" : "वजन"}
                        </InputLabel>
                        <OutlinedInput
                          key={"Weight"}
                          value={formSubmitData?.weight}
                          onChange={handleInputChange}
                          name="weight"
                          id={"Weight"}
                          label={language === "English" ? "Weight" : "वजन"}
                        />
                      </FormControl>
                    </Box>
                  </Col>
                  <Col md="4" className="mb-3">
                    <Box sx={{ display: "flex", flexWrap: "wrap" }}>
                      <FormControl fullWidth variant="outlined">
                        <InputLabel htmlFor="Height">
                          {language === "English" ? "Height" : "उंची"}
                        </InputLabel>
                        <OutlinedInput
                          key={"Height"}
                          onChange={handleInputChange}
                          name="height"
                          value={formSubmitData?.height}
                          id={"Height"}
                          label={language === "English" ? "Height" : "उंची"}
                        />
                      </FormControl>
                    </Box>
                  </Col>
                  <Col md="4" className="mb-3">
                    <Box sx={{ display: "flex", flexWrap: "wrap" }}>
                      <FormControl fullWidth variant="outlined">
                        <InputLabel htmlFor="MarriedAtAge">
                          {language === "English"
                            ? "Married At Age"
                            : "वयानुसार विवाह"}
                        </InputLabel>
                        <OutlinedInput
                          key={"MarriedAtAge"}
                          id={"MarriedAtAge"}
                          value={formSubmitData?.marriedAtAge}
                          onChange={handleInputChange}
                          name="marriedAtAge"
                          label={
                            language === "English"
                              ? "Married At Age"
                              : "वयानुसार विवाह"
                          }
                        />
                      </FormControl>
                    </Box>
                  </Col>
                  <Col md="4" className="mb-3">
                    <Box sx={{ display: "flex", flexWrap: "wrap" }}>
                      <FormControl fullWidth variant="outlined">
                        <InputLabel htmlFor="Children">
                          {language === "English" ? "Children" : "मुले"}
                        </InputLabel>
                        <OutlinedInput
                          key={"Children"}
                          value={formSubmitData?.children}
                          onChange={handleInputChange}
                          name="children"
                          id={"Children"}
                          label={language === "English" ? "Children" : "मुले"}
                        />
                      </FormControl>
                    </Box>
                  </Col>
                  <Col md="4" className="mb-3">
                    <Box sx={{ display: "flex", flexWrap: "wrap" }}>
                      <FormControl fullWidth variant="outlined">
                        <InputLabel htmlFor="IncomeGroup">
                          {language === "English"
                            ? "Income Group"
                            : "उत्पन्न गट"}
                        </InputLabel>
                        <OutlinedInput
                          key={"IncomeGroup"}
                          value={formSubmitData?.incomeGroup}
                          id={"IncomeGroup"}
                          onChange={handleInputChange}
                          name="incomeGroup"
                          label={
                            language === "English"
                              ? "Income Group"
                              : "उत्पन्न गट"
                          }
                        />
                      </FormControl>
                    </Box>
                  </Col>
                </Row>
                {/* <Row className="mt-2">
                  <Col md="2" style={{ alignSelf: "center" }}>
                    <Button
                      variant="outlined"
                      className=""
                      color="success"
                      type="button"
                      // onClick={}
                    >
                      Submit
                    </Button>
                  </Col>
                </Row> */}
              </>
            ) : (
              <></>
            )}
          </CardContent>
        </Card>

        {isUser ? (
          <>
            <Card className="mt-3">
              <CardContent>
                <Typography variant="h5">
                  {language === "English" ? "Malnutrition" : "कुपोषण"}
                </Typography>
                <Row className="mt-3">
                  <Col>
                    <Grid xs={12} sm={12} lg={8}>
                      <Box sx={{ display: "flex", flexWrap: "wrap" }}>
                        {dynamicForm.map((e, i) => (
                          <FormControl
                            key={e.key + "from"}
                            sx={{ m: 1, width: "47%" }}
                            variant="outlined"
                          >
                            <InputLabel key={e.key + "input"} htmlFor={e.label}>
                              {e.label}
                            </InputLabel>
                            <OutlinedInput
                              key={e.key + "output"}
                              id={e.label}
                              name={e.key}
                              onChange={handleInputChange}
                              label={e.label}
                            />
                          </FormControl>
                        ))}
                      </Box>
                    </Grid>
                  </Col>
                </Row>
              </CardContent>
            </Card>
            <Card className="mt-3">
              <CardContent>
                <Typography variant="h5">
                  {language === "English"
                    ? "Hygiene Background"
                    : "स्वच्छता: पृष्ठभूमि"}
                </Typography>
                <Row className="mt-3">
                  <Col>
                    <Grid xs={12} sm={12} lg={8}>
                      <Box sx={{ display: "flex", flexWrap: "wrap" }}>
                        {hygieneDynamicForm.map((e, i) => (
                          <FormControl sx={{ m: 1, width: "47%" }}>
                            <InputLabel key={e.key + "input"} htmlFor={e.label}>
                              {e.label}
                            </InputLabel>
                            <Select
                              key={e.key + "output"}
                              id={e.label}
                              label={e.label}
                              name={e.key}
                              onChange={handleInputChange}
                              onClose={(e) => {
                                document.body.style.overflow = "visible";
                              }}
                            >
                              <MenuItem value="none" disabled>
                                {language === "English" ? "Select" : "निवडा"}
                              </MenuItem>
                              <MenuItem value={"yes"}>
                                {language === "English" ? "Yes" : "होय"}
                              </MenuItem>
                              <MenuItem value={"no"}>
                                {language === "English" ? "No" : "नाही"}
                              </MenuItem>
                            </Select>
                          </FormControl>
                        ))}
                      </Box>
                    </Grid>
                  </Col>
                </Row>
              </CardContent>
            </Card>
            <Card className="mt-3">
              <CardContent>
                <Typography variant="h5">
                  {language === "English"
                    ? "Blood Report"
                    : "स्वच्छता: पृष्ठभूमि"}
                </Typography>
                <Row className="mt-3">
                  <Col>
                    <h6>Customer Upload</h6>
                    {/* <FormControl>
          <OutlinedInput
            type="file"
            id="exampleCustomFileBrowser"
            name="customFile"
            label={"choose an image file"}
            // onChange={handleFileChange}
          />
        <Button color="danger" >
          Upload
        </Button>
        <Button color="secondary" >
          Cancel
        </Button> */}

                    <Box
                      sx={{
                        display: "flex",
                        flexWrap: "wrap",
                        marginTop: "1rem",
                      }}
                    >
                      <FormControl style={{ width: "40%" }} variant="outlined">
                        <OutlinedInput
                          key={"FirstChildAge"}
                          name="customFile"
                          type="file"
                          onChange={handleFileChange}
                          id={"exampleCustomFileBrowser"}
                          label={
                            language === "English"
                              ? "First Child Age"
                              : "पहिलं बाळाचं वय"
                          }
                        />
                      </FormControl>
                    </Box>
                  </Col>
                 
                </Row>
              </CardContent>
            </Card>
            <Card className="mt-3">
              <CardContent>
                <Typography variant="h5">
                  {language === "English"
                    ? "Health Background"
                    : "स्वच्छता: पृष्ठभूमि"}
                </Typography>
                <Row className="mt-3">
                  <Col>
                    <Grid xs={12} sm={12} lg={8}>
                      <Box sx={{ display: "flex", flexWrap: "wrap" }}>
                        {healthData[language == "English" ? "en" : "mr"].map(
                          (e: any, i) => (
                            <FormControl
                              key={e.key + "from"}
                              sx={{ m: 1, width: "47%" }}
                              variant="outlined"
                            >
                              <InputLabel
                                key={e.key + "input"}
                                htmlFor={e.label}
                              >
                                {e.label}
                              </InputLabel>
                              <OutlinedInput
                                key={e.key + "output"}
                                id={e.label}
                                name={e.key}
                                onChange={handleInputChange}
                                label={e.label}
                              />
                            </FormControl>
                          )
                        )}
                      </Box>
                    </Grid>
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col style={{ width: "100%" }}>
                    <Button
                      style={{ width: "10rem", justifyContent: "center" }}
                      variant="outlined"
                      className=""
                      color="success"
                      type="button"
                      onClick={() => {
                        delete formSubmitData.id;
                        updateRecord({
                          ...formSubmitData,
                          phoneNumber: mobileData,
                        });
                      }}
                    >
                      Submit
                    </Button>
                  </Col>
                </Row>
              </CardContent>
            </Card>
          </>
        ) : (
          <></>
        )}
      <FullScreenLoader isLoading={isLoading} />

      </form>
    </>
  );
}

export default GovSingleForm;
