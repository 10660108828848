type appState = {
  sidebarVisible: boolean;
  width: String;
}; // Define an initial state

const initialState: appState = {
  sidebarVisible: false,
  width: "15.5rem",
};

// Define a reducer function to handle state updates
const OverviewReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case "TOGGLE_SIDEBAR":
      return {
        ...state,
        sidebarVisible: !state.sidebarVisible,
        width: state.sidebarVisible ? "300px" : "0px",
      };
    default:
      return state;
  }
};

export default OverviewReducer;
