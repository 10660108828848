import React, { useEffect, useMemo, useRef, useState } from "react";
import { Container, Row, Col, Button, Card, Table } from "reactstrap";
import axiosInstance from "../../utils/AxiosInstance";
import urlConfigs from "../../configs/urlCongfig";
import { ReportData } from "./ReportModel";
import { CSVLink } from "react-csv";
import ReportsHeaderKeys from "./ReportHeaderkey";
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { MaterialReactTable } from "material-react-table";
import { ExportToCsv } from "export-to-csv";
import FullScreenLoader from "../../components/Loaders/FullScreenLoader";
import ErrorAlertModal from "../../components/common/ErrorAlertModal";
import moment from "moment";

const ReportsPage = () => {
  const [csvData, setCsvData] = useState<ReportData[]>([]);
  const [progressData, setProgressData] = useState<ReportData[]>([]);
  const [behaviorDate, setBehaviorDate] = useState<any[]>([]);
  const [campaignData, setCampaignData] = useState<ReportData[]>([]);
  const [goalsData, setGoalsData] = useState([]);
  const isMounted = useRef(false);
  const [customersCount, setCustomersCount] = useState("");

  const [riskAssessmentState, setRiskAssessmentState] = React.useState("");
  const [csvType, setCsvType] = React.useState("");
  const [header, setHeader] = React.useState<any>("");
  const [isLoading, setIsLoading] = useState(false);

  const [isError, setError] = useState({
    error: "",
    active: false,
  });

  function calculatePercentage(part: any, whole: any) {
    return Math.round((part / whole) * 100);
  }

  const handleChange = async (event: SelectChangeEvent) => {
    setCsvType(event.target.value);

    if (event.target.value === "Moringa Score") {
      setHeader(
        ReportsHeaderKeys.moringaCsvHeader.map((e, i) => {
          if (e.key === "BMI") {
            return {
              accessor: e.key,
              accessorKey: e.key,
              enableColumnFilterModes: false,
              filterFn: "equals",
              header: e.label,
              accessorFn: (data: any) =>
                (
                  (data["Weight"] / data["Height"] / data["Height"]) *
                  10000
                ).toFixed() || "Not Provided",
            };
          }
          return {
            accessor: e.key,
            accessorKey: e.key,
            enableColumnFilterModes: false,
            filterFn: "equals",
            header: e.label,
            accessorFn: (data: any) => data[e.key] || "Not Provided",
          };
        })
      );
    } else if (event.target.value === "Sickness Likelihood") {
      setHeader(
        ReportsHeaderKeys.sicknessCsvHeader.map((e, i) => {
          return {
            accessor: e.key,
            accessorKey: e.key,
            enableColumnFilterModes: false,
            filterFn: "equals",
            header: e.label,
            accessorFn: (data: any) => data[e.key] || "Not Provided",
          };
        })
      );
    } else if (event.target.value === "Behavioural Score") {
      await getProgressBehavior();
      setHeader(
        ReportsHeaderKeys.behvaviourCsvHeader.map((e, i) => {
          return {
            accessor: e.key,
            accessorKey: e.key,
            enableColumnFilterModes: false,
            filterFn: "equals",
            header: e.label,
            accessorFn: (data: any) => data[e.key] || "Not Provided",
          };
        })
      );
    } else if (event.target.value === "Progress Report") {
      await getProgressReport();
      setHeader(
        ReportsHeaderKeys.ProgressReportHeader.map((e, i) => {
          return {
            accessor: e.key,
            accessorKey: e.key,
            enableColumnFilterModes: false,
            filterFn: "equals",
            header: e.label,
            accessorFn: (data: any) => data[e.key] || 0,
          };
        })
      );
    } else if (event.target.value === "Goal Compliance") {
      await getGoals();
      setHeader(
        ReportsHeaderKeys.goalComplianceHeader.map((e, i) => {
          if (e.key == "GoalMonitoringFreq") {
            return {
              accessor: e.key,
              accessorKey: e.key,
              enableColumnFilterModes: false,
              filterFn: "equals",
              header: e.label,
              accessorFn: (data: any) =>
                getMonitorValue(data[e.key]) || "Not Provided",
            };
          }
          return {
            accessor: e.key,
            accessorKey: e.key,
            enableColumnFilterModes: false,
            filterFn: "equals",
            header: e.label,
            accessorFn: (data: any) => data[e.key] || "Not Provided",
          };
        })
      );
    } else if (event.target.value === "Campaign Effectiveness") {
      await getCampaigns();
      setHeader(
        ReportsHeaderKeys.campaignHeader.map((e, i) => {
          return {
            accessor: e.key,
            accessorKey: e.key,
            enableColumnFilterModes: false,
            filterFn: "equals",
            header: e.label,
            accessorFn: (data: any) => data[e.key] || "Not Provided",
          };
        })
      );
    } else if (event.target.value === "Good Health Report") {
      setHeader(
        ReportsHeaderKeys.goodHeathReport.map((e, i) => {
          return {
            accessor: e.key,
            accessorKey: e.key,
            enableColumnFilterModes: false,
            filterFn: "equals",
            header: e.label,
            accessorFn: (data: any) => data[e.key] || "Not Provided",
          };
        })
      );
    } else if (event.target.value === "customer_wise") {
      await getProgressBehavior();
      setHeader(
        ReportsHeaderKeys.behvaviourCustomerWise.map((e, i) => {
          return {
            accessor: e.key,
            accessorKey: e.key,
            enableColumnFilterModes: false,
            filterFn: "equals",
            header: e.label,
            accessorFn: (data: any) => data[e.key] || "Not Provided",
          };
        })
      );
    } else if (event.target.value === "Risk Assessment and Mitigation") {
      await riskAssessmentFunc();
      setHeader(
        ReportsHeaderKeys.riskAssessment.map((e, i) => {
          if (e.key === "temp") {
            return {
              accessor: e.key,
              accessorKey: e.key,
              enableColumnFilterModes: false,
              filterFn: "equals",
              header: e.label,
              accessorFn: (data: any) =>
                Math.round(data[e.key] - 273) || "Not Provided",
            };
          }
          return {
            accessor: e.key,
            accessorKey: e.key,
            enableColumnFilterModes: false,
            filterFn: "equals",
            header: e.label,
            accessorFn: (data: any) => data[e.key] || "Not Provided",
          };
        })
      );
    } else if (event.target.value === "Healthcare Resource Allocation") {
      await healthcareResourceFunc();
      setHeader(
        ReportsHeaderKeys.healthcareResource.map((e, i) => {
          if (e.key === "percentage") {
            return {
              accessor: e.key,
              accessorKey: e.key,
              enableColumnFilterModes: false,
              filterFn: "equals",
              header: e.label,
              accessorFn: (data: any) => {
                return (
                  calculatePercentage(data.COUNT, Number(customersCount)) + " %"
                );
              },
            };
          }
          return {
            accessor: e.key,
            accessorKey: e.key,
            enableColumnFilterModes: false,
            filterFn: "equals",
            header: e.label,
            accessorFn: (data: any) => data[e.key] || "Not Provided",
          };
        })
      );
    } else if (event.target.value === "Regulatory Compliance") {
      await regulatoryComplianceFunc();
      setHeader(
        ReportsHeaderKeys.regulatoryCompliance.map((e, i) => {
          if (e.key === "percentage") {
            return {
              accessor: e.key,
              accessorKey: e.key,
              enableColumnFilterModes: false,
              filterFn: "equals",
              header: e.label,
              accessorFn: (data: any) => {
                return (
                  calculatePercentage(data.COUNT, Number(customersCount)) + " %"
                );
              },
            };
          }
          return {
            accessor: e.key,
            accessorKey: e.key,
            enableColumnFilterModes: false,
            filterFn: "equals",
            header: e.label,
            accessorFn: (data: any) => data[e.key] || "Not Provided",
          };
        })
      );
    } else if (event.target.value === "Customized Premiums and Policies") {
      await customizedPremiumsFunc();
      setHeader(
        ReportsHeaderKeys.customizedPremiums.map((e, i) => {
          if (e.key === "percentage") {
            return {
              accessor: e.key,
              accessorKey: e.key,
              enableColumnFilterModes: false,
              filterFn: "equals",
              header: e.label,
              accessorFn: (data: any) => {
                return (
                  calculatePercentage(data.COUNT, Number(customersCount)) + " %"
                );
              },
            };
          }
          return {
            accessor: e.key,
            accessorKey: e.key,
            enableColumnFilterModes: false,
            filterFn: "equals",
            header: e.label,
            accessorFn: (data: any) => data[e.key] || "Not Provided",
          };
        })
      );
    } else if (event.target.value === "Predictive Analytics") {
      await predictiveAnalyticsReport();
      setHeader(
        ReportsHeaderKeys.predictiveAnalytics.map((e, i) => {
          if (e.key === "percentage") {
            return {
              accessor: e.key,
              accessorKey: e.key,
              enableColumnFilterModes: false,
              filterFn: "equals",
              header: e.label,
              accessorFn: (data: any) => {
                return (
                  calculatePercentage(data.COUNT, Number(customersCount)) + " %"
                );
              },
            };
          }
          return {
            accessor: e.key,
            accessorKey: e.key,
            enableColumnFilterModes: false,
            filterFn: "equals",
            header: e.label,
            accessorFn: (data: any) => data[e.key] || "Not Provided",
          };
        })
      );
    } else if (event.target.value === "Data-Driven Underwriting") {
      await dataDrivenUnderwritingReport();
      setHeader(
        ReportsHeaderKeys.dataDrivenUnderwriting.map((e, i) => {
          if (e.key === "percentage") {
            return {
              accessor: e.key,
              accessorKey: e.key,
              enableColumnFilterModes: false,
              filterFn: "equals",
              header: e.label,
              accessorFn: (data: any) => {
                return (
                  calculatePercentage(data.COUNT, Number(customersCount)) + " %"
                );
              },
            };
          }
          return {
            accessor: e.key,
            accessorKey: e.key,
            enableColumnFilterModes: false,
            filterFn: "equals",
            header: e.label,
            accessorFn: (data: any) => data[e.key] || "Not Provided",
          };
        })
      );
    } else if (event.target.value === "Insurance Tailoring report") {
      getSymptomTrendReport();
      setHeader(
        ReportsHeaderKeys.insuranceTailoringReport.map((e, i) => {
          if (e.key === "createdDate") {
            return {
              accessor: e.key,
              accessorKey: e.key,
              enableColumnFilterModes: false,
              filterFn: "equals",
              header: e.label,
              accessorFn: (data: any) =>
                new Date(data[e.key]).toLocaleDateString() || "Not Provided",
            };
          }
          return {
            accessor: e.key,
            accessorKey: e.key,
            enableColumnFilterModes: false,
            filterFn: "equals",
            header: e.label,
            accessorFn: (data: any) => data[e.key] || "Not Provided",
          };
        })
      );
    } else {
      setHeader("");
    }
  };

  const [symptomTrendReport, setSymptomTrendReport] = useState([]);

  const getSymptomTrendReport = () => {
    axiosInstance
      .get(urlConfigs.CUSTOMERSYMPTOMTRENDREPORT)
      .then((response) => {
        if (response.data != null) {
          setSymptomTrendReport(response.data);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        setError({
          error: "Something went wrong!!!",
          active: true,
        });
        setIsLoading(false);
        console.error(error);
      });
  };

  const riskAssessmentFunc = async () => {
    await axiosInstance
      .get(urlConfigs.RISKASSESSMENT)
      .then((response) => {
        response.data.forEach((element: any, i: any) => {
          element.SCOREDATE = moment(element.SCOREDATE).format(
            "DD-MM-YYYY hh:mm a"
          );
        });
        setRiskAssessmentState(response.data);
      })
      .catch((error) => {});
  };

  const [healthcareResource, setHealthcareResource] = useState([]);

  const healthcareResourceFunc = async () => {
    await axiosInstance
      .get(urlConfigs.HEALTHCARE)
      .then((response) => {
        setHealthcareResource(response.data);
      })
      .catch((error) => {});
  };

  const [regulatoryCompliance, setRegulatoryCompliance] = useState([]);

  const regulatoryComplianceFunc = async () => {
    await axiosInstance
      .get(urlConfigs.HEALTHCARE)
      .then((response) => {
        setRegulatoryCompliance(response.data);
      })
      .catch((error) => {});
  };

  const [customizedPremiums, setCustomizedPremiums] = useState([]);

  const customizedPremiumsFunc = async () => {
    await axiosInstance
      .get(urlConfigs.CUSTOMIZEDPREMIUMSREPORT)
      .then((response) => {
        setCustomizedPremiums(response.data);
      })
      .catch((error) => {});
  };

  const [predictiveAnalytics, setPredictiveAnalytics] = useState([]);

  const predictiveAnalyticsReport = async () => {
    await axiosInstance
      .get(urlConfigs.PREDICTIVEANALYTICSREPORT)
      .then((response) => {
        setPredictiveAnalytics(response.data);
      })
      .catch((error) => {});
  };

  const [dataDrivenUnderwriting, setDataDrivenUnderwriting] = useState([]);

  const dataDrivenUnderwritingReport = async () => {
    await axiosInstance
      .get(urlConfigs.PREDICTIVEANALYTICSREPORT)
      .then((response) => {
        setDataDrivenUnderwriting(response.data);
      })
      .catch((error) => {});
  };

  const columns = useMemo(
    () =>
      [
        ...ReportsHeaderKeys.behvaviourCsvHeader.map((e) => {
          return {
            accessorKey: e.key,
            header: e.label,
            size: 50,
          };
        }),
      ] as any,
    []
  );

  const getMonitorValue = (freq: any) => {
    if (freq === 1) {
      return "Daily";
    } else if (freq === 2) {
      return "weekly";
    } else if (freq === 3) {
      return "Monthly";
    }
    return null;
  };

  const exportCSVData = (data: any) => {
    const csvOptions = {
      fieldSeparator: ",",
      quoteStrings: '"',
      decimalSeparator: ".",
      showLabels: true,
      useBom: true,
      useKeysAsHeaders: true,
      // headers: columns.map((c: any) => c.label),
    };

    const csvExporter = new ExportToCsv(csvOptions);

    csvExporter.generateCsv(data);
  };

  useEffect(() => {
    if (!isMounted.current) {
      let count = localStorage.getItem("customerCount");
      console.log(count);

      setCustomersCount(count ?? "");
      getJobDetails();
    }

    isMounted.current = true;
  }, []);

  const getProgressReport = async () => {
    setIsLoading(true);
    await axiosInstance
      .get(urlConfigs.PROGRESS_REPORT)
      .then((response) => {
        response.data[0].forEach((element: any) => {
          element.name = "*****";
          if (element?.age != null) {
            element.age = calculateAge(element?.age);
          }
          element.SCOREDATE = new Date(element.SCOREDATE).toLocaleDateString();
        });
        setProgressData(response.data[0]);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);

        setError({
          error: error?.data?.errors ?? "Something went wrong.",
          active: true,
        });
        setIsLoading(false);
      });
  };

  const getProgressBehavior = async () => {
    setIsLoading(true);
    await axiosInstance
      .get(urlConfigs.BEHAVIOR_REPORT)
      .then((response) => {
        setBehaviorDate(response.data[0]);
        setIsLoading(false);
      })
      .catch((error) => {
        setError({
          error: error.data.errors ?? "Something went wrong.",
          active: true,
        });
        setIsLoading(false);
      });
  };

  const getGoals = async () => {
    setIsLoading(true);
    await axiosInstance
      .get(urlConfigs.GOALS_REPORT)
      .then((response) => {
        response.data.forEach((element: any) => {
          if (element?.age != null) {
            element.age = calculateAge(element?.age);
          }
          element.GoalStartDate = new Date(
            element.GoalStartDate
          ).toLocaleDateString();
          element.GoalEndDate = new Date(
            element.GoalEndDate
          ).toLocaleDateString();

          element.customer_name = "*****";
        });
        setGoalsData(response.data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);

        setError({
          error: error.data.errors ?? "Something went wrong.",
          active: true,
        });
        setIsLoading(false);
      });
  };

  const getJobDetails = () => {
    setIsLoading(true);
    axiosInstance
      .get(urlConfigs.CUSTOMER_LIST)
      .then((response) => {
        response.data.forEach((e: any) => {
          if (e?.age != null) {
            e.age = calculateAge(e?.age);
          }
        });
        setCsvData(response.data);
        setIsLoading(false);
      })
      .catch((error) => {
        setError({
          error: error.data.errors ?? "Something went wrong.",
          active: true,
        });
        setIsLoading(false);
      });
  };

  const getCampaigns = async () => {
    setIsLoading(true);
    await axiosInstance
      .get(urlConfigs.CAMPAIGN)
      .then((response) => {
        response.data.forEach((element: any) => {
          element.CampaignStartDate = new Date(
            element.CampaignStartDate
          ).toLocaleDateString();
          element.CampaingEndDate = new Date(
            element.CampaingEndDate
          ).toLocaleDateString();
        });
        setCampaignData(response.data);

        setIsLoading(false);
      })
      .catch((error) => {
        setError({
          error: error.data.errors ?? "Something went wrong.",
          active: true,
        });
        setIsLoading(false);
      });
  };

  const convertCSV = (data: any) => {
    data.forEach((element: any) => {
      element.customer_name = "*****";
      element.email = "*****";
      element.phone_number = "*****";

      let total_scores = element.moringaScore;

      if (total_scores < 60) {
        element.category = "Critical";
      } else if (total_scores >= 60 && total_scores < 80) {
        element.category = "Critical";
      } else {
        element.category = "Healthy";
      }

      element.profile.forEach((e: any) => {
        element[e?.question?.file_field] = e?.answerText;
      });
    });

    return data;
  };

  const getExcelData = () => {
    if (csvType === "Progress Report") {
      return progressData;
    } else if (csvType === "Behavioural Score") {
      return behaviorDate;
    } else if (csvType === "Campaign Effectiveness") {
      return campaignData;
    } else if (csvType === "Goal Compliance") {
      return goalsData;
    } else if (csvType === "customer_wise") {
      let array = [];
      for (let index = 0; index < behaviorDate.length; index++) {
        const element = behaviorDate[index];

        console.log(element.OverallScore);
        if (element.OverallScore !== 0) {
          array.push({
            ...element,

            child: behaviorDate.filter(
              (e: any) =>
                e.id === element.id && e.ModelRunDate === element.ModelRunDate
            ),
          });
        }
      }
      console.log(array);

      return array;
    } else if (csvType === "Healthcare Resource Allocation") {
      return healthcareResource;
    } else if (csvType === "Regulatory Compliance") {
      return regulatoryCompliance;
    } else if (csvType === "Risk Assessment and Mitigation") {
      return riskAssessmentState;
    } else if (csvType === "Customized Premiums and Policies") {
      return customizedPremiums;
    } else if (csvType === "Predictive Analytics") {
      return predictiveAnalytics;
    } else if (csvType === "Data-Driven Underwriting") {
      return dataDrivenUnderwriting;
    } else if (csvType === "Insurance Tailoring report") {
      return symptomTrendReport;
    }

    return convertCSV(csvData);
  };

  const calculateAge = (birthYear: number): number => {
    const currentYear: number = new Date().getFullYear();
    const age: number = currentYear - birthYear;
    return age;
  };
  return (
    <Container>
      <div className="mt-3">
        <FormControl sx={{ m: 1, minWidth: "20rem" }}>
          <InputLabel id="demo-simple-select-helper-label">
            Select Type of report
          </InputLabel>
          <Select
            labelId="demo-simple-select-helper-label"
            id="demo-simple-select-helper"
            value={csvType}
            label="Select Type of report"
            onChange={handleChange}
            onClose={(e) => {
              document.body.style.overflow = "visible";
            }}
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            <MenuItem value={"Moringa Score"}>Moringa Score</MenuItem>
            <MenuItem value={"Sickness Likelihood"}>
              Sickness Likelihood
            </MenuItem>
            <MenuItem value={"Behavioural Score"}>Behavioural Score</MenuItem>
            <MenuItem value={"Progress Report"}>Progress Report</MenuItem>
            <MenuItem value={"Goal Compliance"}>Goal Compliance</MenuItem>
            <MenuItem value={"Campaign Effectiveness"}>
              Campaign Effectiveness
            </MenuItem>
            {/* <MenuItem value={"Good Health Report"}>Good Health Report</MenuItem> */}
            {/* <MenuItem value={"customer_wise"}>Customer Wise Report</MenuItem> */}
            <MenuItem value={"Risk Assessment and Mitigation"}>
              Risk Assessment and Mitigation
            </MenuItem>
            <MenuItem value={"Healthcare Resource Allocation"}>
              Healthcare Resource Allocation
            </MenuItem>
            <MenuItem value={"Regulatory Compliance"}>
              Regulatory Compliance
            </MenuItem>
            <MenuItem value={"Customized Premiums and Policies"}>
              Customized Premiums and Policies
            </MenuItem>
            <MenuItem value={"Predictive Analytics"}>
              Predictive Analytics
            </MenuItem>
            <MenuItem value={"Data-Driven Underwriting"}>
              Data-Driven Underwriting
            </MenuItem>
            <MenuItem value={"Insurance Tailoring report"}>
              Insurance Tailoring report
            </MenuItem>
          </Select>
        </FormControl>
      </div>

      {header !== "" ? (
        <Card
          style={{ borderRadius: 10 }}
          className="shadow p-3 pt-4 mb-5 bg-white "
        >
          {csvType === "customer_wise" ? (
            <MaterialReactTable
              muiTableHeadCellProps={{
                sx: (theme) => ({
                  background: "#faf4d4",
                  color: theme.palette.text.primary,
                }),
              }}
              muiTableBodyRowProps={{
                sx: {
                  backgroundColor: "rgb(228, 241, 221)",
                  borderRight: "1px solid rgba(224,224,224,1)",
                },
              }}
              muiTopToolbarProps={{
                sx: (theme) => ({
                  background: "#faf4d4",
                  color: theme.palette.text.primary,
                }),
              }}
              muiBottomToolbarProps={{
                sx: (theme) => ({
                  background: "#faf4d4",
                  color: theme.palette.text.primary,
                }),
              }}
              columns={header}
              data={getExcelData()}
              enableTableFooter={false}
              renderDetailPanel={({ row }: any) => (
                <MaterialReactTable
                  muiTableHeadCellProps={{
                    sx: (theme) => ({
                      background: "#faf4d4",
                      color: theme.palette.text.primary,
                    }),
                  }}
                  muiTableBodyRowProps={{
                    sx: {
                      backgroundColor: "rgb(228, 241, 221)",
                      borderRight: "1px solid rgba(224,224,224,1)",
                    },
                  }}
                  muiTopToolbarProps={{
                    sx: (theme) => ({
                      background: "#faf4d4",
                      color: theme.palette.text.primary,
                    }),
                  }}
                  muiBottomToolbarProps={{
                    sx: (theme) => ({
                      background: "#faf4d4",
                      color: theme.palette.text.primary,
                    }),
                  }}
                  columns={columns}
                  data={row.original.child}
                />
              )}
              state={{
                isLoading,
                showProgressBars: isLoading,
              }}
              renderTopToolbarCustomActions={({ table }) => (
                <Box
                  sx={{
                    display: "flex",
                    gap: "1rem",
                    p: "0.5rem",
                    flexWrap: "wrap",
                  }}
                >
                  <Button
                    color="primary"
                    //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
                    onClick={() => {
                      const data = table.getCoreRowModel().rows.map((value) => {
                        const obj: any = {};

                        header.forEach((e: any) => {
                          obj[e.header] = value.getValue(e.accessor);
                        });

                        return obj;
                      });

                      exportCSVData(data);
                    }}
                    variant="contained"
                  >
                    Export All
                  </Button>

                  <Button
                    disabled={table.getRowModel().rows.length === 0}
                    onClick={() => {
                      const data = table.getRowModel().rows.map((value) => {
                        const obj: any = {};

                        header.forEach((e: any) => {
                          obj[e.header] = value.getValue(e.accessor);
                        });

                        return obj;
                      });

                      exportCSVData(data);
                    }}
                    variant="contained"
                  >
                    Export Page
                  </Button>
                  <Button
                    disabled={
                      !table.getIsSomeRowsSelected() &&
                      !table.getIsAllRowsSelected()
                    }
                    onClick={() => {
                      const data = table
                        .getSelectedRowModel()
                        .rows.map((value) => {
                          const obj: any = {};

                          header.forEach((e: any) => {
                            obj[e.header] = value.getValue(e.accessor);
                          });

                          return obj;
                        });

                      exportCSVData(data);
                    }}
                    variant="contained"
                  >
                    Export Selected Record
                  </Button>
                </Box>
              )}
            />
          ) : (
            <MaterialReactTable
              muiTableHeadCellProps={{
                sx: (theme) => ({
                  background: "#faf4d4",
                  color: theme.palette.text.primary,
                }),
              }}
              muiTableBodyRowProps={{
                sx: {
                  backgroundColor: "rgb(228, 241, 221)",
                  borderRight: "1px solid rgba(224,224,224,1)",
                },
              }}
              muiTopToolbarProps={{
                sx: (theme) => ({
                  background: "#faf4d4",
                  color: theme.palette.text.primary,
                }),
              }}
              muiBottomToolbarProps={{
                sx: (theme) => ({
                  background: "#faf4d4",
                  color: theme.palette.text.primary,
                }),
              }}
              columns={header}
              data={getExcelData()}
              positionToolbarAlertBanner="bottom"
              enableRowSelection
              state={{
                isLoading,
                showProgressBars: isLoading,
                columnOrder: [
                  "mrt-row-select",
                  ...header.map((e: any) => e.accessorKey),
                ],
              }}
              renderTopToolbarCustomActions={({ table }) => (
                <Box
                  sx={{
                    display: "flex",
                    gap: "1rem",
                    p: "0.5rem",
                    flexWrap: "wrap",
                  }}
                >
                  <Button
                    color="primary"
                    //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
                    onClick={() => {
                      const data = table.getCoreRowModel().rows.map((value) => {
                        const obj: any = {};

                        header.forEach((e: any) => {
                          obj[e.header] = value.getValue(e.accessor);
                        });

                        return obj;
                      });

                      exportCSVData(data);
                    }}
                    variant="contained"
                  >
                    Export All
                  </Button>

                  <Button
                    disabled={table.getRowModel().rows.length === 0}
                    onClick={() => {
                      const data = table.getRowModel().rows.map((value) => {
                        const obj: any = {};

                        header.forEach((e: any) => {
                          obj[e.header] = value.getValue(e.accessor);
                        });

                        return obj;
                      });

                      exportCSVData(data);
                    }}
                    variant="contained"
                  >
                    Export Page
                  </Button>
                  <Button
                    disabled={
                      !table.getIsSomeRowsSelected() &&
                      !table.getIsAllRowsSelected()
                    }
                    onClick={() => {
                      const data = table
                        .getSelectedRowModel()
                        .rows.map((value) => {
                          const obj: any = {};

                          header.forEach((e: any) => {
                            obj[e.header] = value.getValue(e.accessor);
                          });

                          return obj;
                        });

                      exportCSVData(data);
                    }}
                    variant="contained"
                  >
                    Export Selected Record
                  </Button>
                </Box>
              )}
            />
          )}
        </Card>
      ) : (
        <></>
      )}
      <ErrorAlertModal
        isOpen={isError.active}
        onClose={() =>
          setError({
            error: "",
            active: false,
          })
        }
        title={"Error"}
        body={isError.error}
      ></ErrorAlertModal>
      <FullScreenLoader isLoading={isLoading} />
    </Container>
  );
};

type CsvButtonViewProp = { title: string; csvData: any; headers: any };

const CsvButtonView = ({ title, csvData, headers }: CsvButtonViewProp) => {
  return (
    <div
      className="text-center p-4"
      style={{
        background: "#DDE4EB",
      }}
    >
      <p className="pt-3" style={{ textAlign: "center" }}>
        {title}
      </p>

      <Button
        style={{ background: "#212529" }}
        className="btn-block mt-2 btn-dark"
      >
        <CSVLink
          style={{ color: "white", textDecoration: "none" }}
          data={csvData}
          headers={headers}
          filename={`${title}.csv`}
          onClick={(e: any) => e.stopPropagation()}
        >
          Download CSV
        </CSVLink>
      </Button>
    </div>
  );
};

export default ReportsPage;
