import React, { useEffect, useRef, useState } from "react";
import axiosInstance from "../../../utils/AxiosInstance";
import urlConfigs from "../../../configs/urlCongfig";
import { Card, Container } from "reactstrap";
import { MaterialReactTable } from "material-react-table";
import FullScreenLoader from "../../../components/Loaders/FullScreenLoader";
import { Link } from "react-router-dom";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
} from "@mui/material";
import HReportsHeaderKeys from "./HReportHeaderkey";

type Props = {};

const HCustomerReportView = (props: Props) => {
  const [csvType, setCsvType] = React.useState("");
  const [header, setHeader] = React.useState<any>("");
  const customerBody: any = {
    index: "#",
    org_id: "Customer id",
    customer_name: "Customer name",
    email: "Email",
    phone_number: "Phone number",
    moringaScore: "Moringa Scores",
    createdAt: "Upload data",
    Age: "Age",
    baseLocation: "Location",
    Gender: "Gender",
    Height: "Height",
    Weight: "Weight",
    BMI: "BMI",
    "Blood Group": "Blood Group",
    "Nature of Job": "Nature of Job",
    "Activity Level": "Activity Level",
    "Exercise Level": "Exercise Level",
    "Eating Habits": "Eating Habits",
    "Sleep Duration": "Sleep Duration",
    "Any Surgical History?": "Any Surgical History?",
    "Any Medical History?": "Any Medical History",
    Smoking: "Smoking",
    Drinking: "Drinking",
    "Family Medical History?": "Family Medical History?",
    "Changes in eating habits?": "Changes in eating habits?",
    "Changes in sleeping pattern?": "Changes in sleeping pattern?",
    "Any stress?": "Any stress?",
    "Worry a lot?": "Worry a lot?",
    "Confident?": "Confident?",
    "Need support?": "Need support?",
    "Enjoy community?": "Enjoy community?",
  };

  const tableHeaderData: any = Object.keys(customerBody).map((key) => {
    return {
      accessor: key,
      accessorKey: key,
      enableColumnFilterModes: false,
      filterFn: "equals",
      header: customerBody[key],
      accessorFn: (data: any) => data[key] || "Not Provided",
    };
  });

  const [isLoading, setIsLoading] = useState(false);
  const [jobList, setJobList] = useState([]);
  const isMounted = useRef(false);

  const handleChange = async (event: SelectChangeEvent) => {
    setCsvType(event.target.value);

    if (event.target.value === "Customer Report") {
      getJobDetails();

      setHeader(
        HReportsHeaderKeys.insuranceTailoringReport.map((e, i) => {
          if (e.key === "createdDate") {
            return {
              accessor: e.key,
              accessorKey: e.key,
              enableColumnFilterModes: false,
              filterFn: "equals",
              header: e.label,
              accessorFn: (data: any) =>
                new Date(data[e.key]).toLocaleDateString() || "Not Provided",
            };
          }
          return {
            accessor: e.key,
            accessorKey: e.key,
            enableColumnFilterModes: false,
            filterFn: "equals",
            header: e.label,
            accessorFn: (data: any) => data[e.key] || "Not Provided",
          };
        })
      );
    } else {
      setHeader("");
    }
  };

  const getJobDetails = () => {
    axiosInstance
      .get(urlConfigs.CUSTOMERSYMPTOMTRENDREPORT)
      .then((response) => {
        setJobList(response.data);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        console.error(error);
      });
  };

  const calculateAge = (birthYear: number): number => {
    const currentYear: number = new Date().getFullYear();
    const age: number = currentYear - birthYear;
    return age;
  };

  return (
    <>
      <div>
        <Container fluid className="vh-100">
          <FormControl sx={{ m: 1, minWidth: "20rem" }}>
            <InputLabel id="demo-simple-select-helper-label">
              Select Type of report
            </InputLabel>
            <Select
              labelId="demo-simple-select-helper-label"
              id="demo-simple-select-helper"
              value={csvType}
              label="Select Type of report"
              onChange={handleChange}
              onClose={(e) => {
                document.body.style.overflow = "visible";
              }}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>

              <MenuItem value={"Customer Report"}>Customer Report</MenuItem>
            </Select>
          </FormControl>
          {header !== "" && (
            <Card
              style={{ borderRadius: 10 }}
              className="shadow p-3 pt-4 mb-5 bg-white "
            >
              <MaterialReactTable
                muiTableHeadCellProps={{
                  sx: (theme) => ({
                    background: "#faf4d4",
                    color: theme.palette.text.primary,
                  }),
                }}
                muiTableBodyRowProps={{
                  sx: {
                    backgroundColor: "rgb(228, 241, 221)",
                    borderRight: "1px solid rgba(224,224,224,1)",
                  },
                }}
                muiTopToolbarProps={{
                  sx: (theme) => ({
                    background: "#faf4d4",
                    color: theme.palette.text.primary,
                  }),
                }}
                muiBottomToolbarProps={{
                  sx: (theme) => ({
                    background: "#faf4d4",
                    color: theme.palette.text.primary,
                  }),
                }}
                columns={header}
                data={jobList}
                columnResizeMode="onChange"
                initialState={{ showColumnFilters: false }} //show filters by default
                filterFns={{
                  customFilterFn: (row, id, filterValue) => {
                    return row.getValue(id) === filterValue;
                  },
                }}
              />
            </Card>
          )}
        </Container>
        <FullScreenLoader isLoading={isLoading} />
      </div>
    </>
  );
};

export default HCustomerReportView;
