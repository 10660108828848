import React, { useEffect, useRef, useState } from "react";
import { Card, Container, Nav, Navbar } from "reactstrap";
import { MaterialReactTable } from "material-react-table";
import FullScreenLoader from "../../../components/Loaders/FullScreenLoader";
import urlConfigs from "../../../configs/urlCongfig";
import axiosInstance from "../../../utils/AxiosInstance";
import HospitalDetailsModal from "../../../components/common/HospitalDetailsModal";
import VolunteerCreateUserModal from "../../../components/common/VolunteerCreateUserModal";
import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";

type Props = {};

const VHomeView = (props: Props) => {
  const [isLoading, setIsLoading] = useState(false);

  const fieldShow = [
    { label: "#", key: "index" },
    { label: "Customer Id", key: "customer_id" },
    { label: "Name", key: "name" },
    { label: "Created At", key: "created_at" },
  ];

  const tableHeaderData: any = fieldShow.map((key) => {
    if (key.key == "created_at") {
      return {
        accessor: key.key,
        accessorKey: key.key,
        enableColumnFilterModes: false,
        filterFn: "equals",
        header: key.label,
        accessorFn: (data: any) =>
          new Date(data[key.key]).toLocaleString() || "Not Provided",
      };
    }
    return {
      accessor: key.key,
      accessorKey: key.key,
      enableColumnFilterModes: false,
      filterFn: "equals",
      header: key.label,
      accessorFn: (data: any) => data[key.key].toString() || "Not Provided",
    };
  });

  const [jobList, setJobList] = useState([]);
  const [customerList, setCustomerList] = useState([]);

  const isMounted = useRef(false);

  useEffect(() => {
    if (!isMounted.current) {
      getOverView();
    }

    isMounted.current = true;
  }, []);

  const getCustomerByid = (id: any) => {
    setIsLoading(true);
    axiosInstance
      .get(urlConfigs.VOLUNTEER + "/customer/" + id)
      .then((response) => {
        response.data.forEach((element: any, i: any) => {
          element.index = i + 1;
        });
        setCustomerList(response.data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
      });
  };

  const getOverView = () => {
    setIsLoading(true);
    axiosInstance
      .get(urlConfigs.VOLUNTEER)
      .then((response) => {
        console.log(response.data);
        response.data.forEach((element: any, i: any) => {
          element.index = i + 1;
        });
        setJobList(response.data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
      });
  };

  return (
    <Container fluid className="vh-100" style={{ minWidth: "100%" }}>
      <Navbar className="mt-2 mb-2" color="light">
        <FormControl sx={{ minWidth: "40%" }}>
          <InputLabel id="demo-simple-select-helper-label">
            Select Volunteer
          </InputLabel>
          <Select
            name="select_volunteer"
            id="outlined-adornment-email"
            label="Select Volunteer"
            onChange={(e, val: any) => {
              if (val.props.value == "") {
                setCustomerList([]);
                return;
              }
              getCustomerByid(val.props.value);
            }}
            onClose={(e) => {
              document.body.style.overflow = "visible";
            }}
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>

            {jobList.map((e: any) => (
              <MenuItem value={e.id}>{e.name}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </Navbar>
      <Card
        style={{ borderRadius: 10 }}
        className="shadow p-3 pt-4 mb-5 bg-white "
      >
        <MaterialReactTable
          muiTableHeadCellProps={{
            sx: (theme) => ({
              background: "#faf4d4",
              color: theme.palette.text.primary,
            }),
          }}
          muiTableBodyRowProps={{
            sx: {
              backgroundColor: "rgb(228, 241, 221)",
              borderRight: "1px solid rgba(224,224,224,1)",
            },
          }}
          muiTopToolbarProps={{
            sx: (theme) => ({
              background: "#faf4d4",
              color: theme.palette.text.primary,
            }),
          }}
          muiBottomToolbarProps={{
            sx: (theme) => ({
              background: "#faf4d4",
              color: theme.palette.text.primary,
            }),
          }}
          columns={tableHeaderData}
          data={customerList}
          columnResizeMode="onChange"
          initialState={{ showColumnFilters: false }} //show filters by default
          filterFns={{
            customFilterFn: (row, id, filterValue) => {
              return row.getValue(id) === filterValue;
            },
          }}
        />
      </Card>

      <FullScreenLoader isLoading={isLoading} />
    </Container>
  );
};

export default VHomeView;
