import RotateLoader from "react-spinners/RotateLoader";
import { Modal } from "reactstrap";

interface SidebarProps {
  isLoading: boolean;
}

const FullScreenLoader: React.FC<SidebarProps> = ({ isLoading }) => {
  if (!isLoading) {
    return <div />;
  }
  return (
    <Modal contentClassName="loader" centered isOpen={isLoading}>
      <div
        style={{
          justifyContent: "center",
          display: "flex",
          alignItems: "center",
          zIndex: 999999999,
        }}
      >
        <RotateLoader />
      </div>
    </Modal>
  );
};

export default FullScreenLoader;
