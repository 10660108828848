import React, { useEffect, useRef, useState } from "react";
import axiosInstance from "../../../utils/AxiosInstance";
import urlConfigs from "../../../configs/urlCongfig";
import { Card, Container } from "reactstrap";
import { MaterialReactTable } from "material-react-table";
import FullScreenLoader from "../../../components/Loaders/FullScreenLoader";
import { Link } from "react-router-dom";

type Props = {};

const GovCustomerRecordsPage = (props: Props) => {
  const customerBody: any = {
    CustomerId: "Customer Id",
    created_at: "Created At",
    BodyMassIndexBMI: "BodyMassIndexBMI",
    DietaryAssessment: "DietaryAssessment",
    LaboratoryTests: "LaboratoryTests",
    ParentalSocioeconomicFactors: "ParentalSocioeconomicFactors",
    ClinicalAssessmentSkinHairNails: "ClinicalAssessmentSkinHairNails",
    MuscleWasting: "MuscleWasting",
    MedicalHistory: "MedicalHistory",
    WeightForAge: "WeightForAge",
    HeightForAge: "HeightForAge",
    WeightForHeight: "WeightForHeight",
    MidUpperArmCircumferenceMUAC: "MidUpperArmCircumferenceMUAC",
    DevelopmentalMilestones: "DevelopmentalMilestones",
    SocioeconomicFactors: "SocioeconomicFactors",
    WeightLoss: "WeightLoss",
    MentalHealth: "MentalHealth",
    BoneHealth: "BoneHealth",
    PhysicalPerformance: "PhysicalPerformance",
    FunctionalStatus: "FunctionalStatus",
    WeightGain: "WeightGain",
    EdemaSwelling: "EdemaSwelling",
    DevelopmentalMilestonesOfTheFetus: "DevelopmentalMilestonesOfTheFetus",
    ReproductiveHealthHistory: "ReproductiveHealthHistory",
    MenstrualIrregularities: "MenstrualIrregularities",
    createdAt: "createdAt",
    cleanBody: "cleanBody",
    washingBody: "washingBody",
    teethBrushing: "teethBrushing",
    sneezing: "sneezing",
    petsCare: "petsCare",
    clothingCare: "clothingCare",
    menstrualProduct: "menstrualProduct",
    genitalClean: "genitalClean",
    padsCare: "padsCare",
    drink: "drink",
    monitorPeriods: "monitorPeriods",
    checkUp: "checkUp",
    Weightloss: "Weightloss",
    Edema: "Edema",
    DevelopmentMilestonesOfFeotus: "DevelopmentMilestonesOfFeotus",
  };
  const tableHeaderData: any = Object.keys(customerBody).map((key) => {
    if (key === "org_id") {
      return {
        accessor: key,
        accessorKey: key,
        enableColumnFilterModes: false,
        filterFn: "equals",
        header: customerBody[key],
        Cell: ({ cell, row }: any) => {
          return (
            <Link to={`/profile?id=${row.original.id}`}>
              {" "}
              {cell.getValue()}
            </Link>
          );
        },
      };
    }
    return {
      accessor: key,
      accessorKey: key,
      enableColumnFilterModes: false,
      filterFn: "equals",
      header: customerBody[key],
      accessorFn: (data: any) => data[key] || "Not Provided",
    };
  });

  useEffect(() => {
    getJobDetails();
  }, []);

  const [isLoading, setIsLoading] = useState(false);
  const [jobList, setJobList] = useState([]);
  const isMounted = useRef(false);

  const getJobDetails = () => {
    axiosInstance
      .get(urlConfigs.gov + "/all")
      .then((response) => {
        response.data.list.forEach((element: any, i: any) => {
          element.index = i + 1;
          element.created_at = new Date(
            element.created_at
          ).toLocaleDateString();
        });
        setJobList(response.data.list);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        console.error(error);
      });
  };

  const calculateAge = (birthYear: number): number => {
    const currentYear: number = new Date().getFullYear();
    const age: number = currentYear - birthYear;
    return age;
  };

  return (
    <>
      <div>
        <Container fluid className="vh-100">
          <Card
            style={{ borderRadius: 10 }}
            className="shadow p-3 pt-4 mb-5 bg-white "
          >
            <MaterialReactTable
              muiTableHeadCellProps={{
                sx: (theme) => ({
                  background: "#faf4d4",
                  color: theme.palette.text.primary,
                }),
              }}
              muiTableBodyRowProps={{
                sx: {
                  backgroundColor: "rgb(228, 241, 221)",
                  borderRight: "1px solid rgba(224,224,224,1)",
                },
              }}
              muiTopToolbarProps={{
                sx: (theme) => ({
                  background: "#faf4d4",
                  color: theme.palette.text.primary,
                }),
              }}
              muiBottomToolbarProps={{
                sx: (theme) => ({
                  background: "#faf4d4",
                  color: theme.palette.text.primary,
                }),
              }}
              columns={tableHeaderData}
              data={jobList}
              columnResizeMode="onChange"
              initialState={{ showColumnFilters: false }} //show filters by default
              filterFns={{
                customFilterFn: (row, id, filterValue) => {
                  return row.getValue(id) === filterValue;
                },
              }}
            />
          </Card>
        </Container>
        <FullScreenLoader isLoading={isLoading} />
      </div>
    </>
  );
};

export default GovCustomerRecordsPage;
