import axios, { AxiosInstance, AxiosError, AxiosResponse } from "axios";
import appConfigs from "../configs/appConfig";

// Create an Axios instance
const axiosInstance: AxiosInstance = axios.create({
  baseURL: appConfigs.baseUrl,
});

// Request interceptor
axiosInstance.interceptors.request.use(
  (config: any) => {
    // Modify config or add headers before sending the request
    // For example, you can add an authentication token

    if (getToken()) {
      config.headers["Authorization"] = getToken();
    }
    return config;
  },
  (error: AxiosError) => {
    // Handle request errors
    return Promise.reject(error);
  }
);

// Response interceptor
axiosInstance.interceptors.response.use(
  (response: AxiosResponse) => {
    // Modify response data before returning
    return response.data;
  },
  (error: any) => {
    // Handle response errors

    if (error?.response) {
      if (error?.response?.status === 401) {
        localStorage.removeItem("accessToken");
        window.location.href = "/login";
      }

      if (error?.response?.data?.data?.status_code === 401) {
        localStorage.removeItem("accessToken");
        window.location.href = "/login";
      }
      console.error("Response Error:", error?.response?.data);
    } else if (error?.request) {
      console.error("Request Error:", error?.request);
    } else {
      console.error("Error:", error?.message);
    }
    return Promise.reject(error.response);
  }
);

const getToken = () => {
  let data = localStorage.getItem("accessToken");
  return data;
};

export default axiosInstance;
