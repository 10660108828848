const ProfileReportHeaderKeys = {
  healthAssessmentReport: [
    { key: "Age", label: "Age" },
    { key: "Gender", label: "Gender" },
    { key: "BMI", label: "BMI" },
    { key: "Weight", label: "Weight" },
    { key: "Height", label: "Height" },
    { key: "Blood Group", label: "Blood Group" },
    { key: "Activity Level", label: "Activity Level" },
    { key: "Exercise Level", label: "Exercise Level" },
    { key: "Eating Habits", label: "Eating Habits" },
    { key: "Sleep Duration", label: "Sleep Duration" },
    { key: "Health Condition", label: "Health Condition" },
    { key: "Blood Pressure", label: "Blood Pressure" },
    { key: "Diabetes", label: "Diabetes" },
    { key: "Cholesterol", label: "Cholesterol" },
    { key: "Chronic Kidney Disease", label: "Chronic Kidney Disease" },
    { key: "Heart Disease", label: "Heart Disease" },
    { key: "Stroke", label: "Stroke" },
    { key: "Tuberculosis", label: "Tuberculosis" },
    { key: "Asthma", label: "Asthma" },
    { key: "Allergy", label: "Allergy" },
    { key: "Tobacco", label: "Tobacco" },
    { key: "Drinking", label: "Alcohol" },
  ],
  symptomTrendReport: [
    { key: "CustomerId", label: "Customer Id" },
    { key: "diseaseId", label: "Disease Id" },
    { key: "illnessType", label: "Illness Type" },
    { key: "name", label: "Disease name" },
  ],

  insuranceTailoringReport: [
    { key: "customerId", label: "Customer Id" },
    { key: "customerName", label: "Customer Name" },
    { key: "createdDate", label: "Date" },
    { key: "symptomsNames", label: "Symptoms Names" },
    { key: "diseaseNames", label: "Disease Names" },
    { key: "city", label: "City" },
  ],
};

export default ProfileReportHeaderKeys;
